import { InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider'
import { cognitoClient } from '../commons/cognitoClient'
import { ApiError, NotAuthenticatedError } from '../commons/errors'
import { LocalStorageKey } from '../commons/localStorageKey'
import { ensure } from '../commons/utils'

const baseURL = process.env['NEXT_PUBLIC_API_ENDPOINT']
let refreshTokenPromise: Promise<string> | null = null

function redirect(): never {
  localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN)
  localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN)
  throw new NotAuthenticatedError()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function parse(response: Response): Promise<any> {
  const contentType = response.headers.get('Content-Type')

  if (contentType?.startsWith('application/json')) {
    return response.json()
  } else {
    return response.blob()
  }
}

export default async function customClient<Return, BodyData = unknown>({
  url: path,
  method,
  params,
  headers = {},
  data,
}: // responseType,
{
  url: string
  method: string
  params?: Record<string, string | number | boolean>
  headers?: Record<string, string>
  data?: BodyData
  responseType?: string
}): Promise<Return> {
  const searchParams = params
    ? `?${new URLSearchParams(Object.entries(params).map(([key, value]) => [key, value.toString()]))}`
    : ''
  // build a url
  const url = new URL(`${baseURL}${path}${searchParams}`)

  // 一部のエンドポイントは、タブが閉じてもリクエストが中断されないようにする
  // keepalive はリクエストバイト数に上限(64KiB)があるので注意すること
  const keepalive = path.startsWith('/public/lead-session-events') && method === 'POST'

  // get tokens to authorize
  const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)
  // 未認証の場合
  if (!accessToken) {
    // TODO: 未認証でAPIコールするのはpublicエンドポイントだけなので、
    // publicエンドポイント用のクライアントを新しく作成し loovPublic.ts からはそちらを参照するようにして、
    // このクライアントからは分岐を削除する
    const response = await fetch(url, {
      keepalive,
      method,
      headers,
      ...(data && { body: JSON.stringify(data) }),
    })

    // 成功
    if (response.ok) {
      return parse(response)
    }

    // 401 の場合はリダイレクト
    if (response.status === 401) {
      redirect()
    }

    // エラー
    throw new ApiError(response)
  }

  // 認証済みの場合
  // アクセストークンを付与
  headers['Authorization'] = `Bearer ${accessToken}`
  // システム管理者がテナントの切替を行っている場合はヘッダーを追加
  const tenantId = localStorage.getItem(LocalStorageKey.SWITCH_TENANT_ID)
  if (tenantId) {
    headers['X-tenant-id'] = tenantId
  }

  // リクエスト
  const response = await fetch(url, {
    keepalive,
    method,
    headers,
    ...(data && { body: JSON.stringify(data) }),
  })

  // 成功
  if (response.ok) {
    return parse(response)
  }

  // 401 以外はエラー
  if (response.status !== 401) {
    throw new ApiError(response)
  }

  // 401 の場合はトークンをリフレッシュしてリトライを試みる
  // 同時実行制御のため、グローバルに Promise を保持する
  refreshTokenPromise = refreshTokenPromise ?? refreshToken()

  const newAccessToken = await refreshTokenPromise.catch((e) => {
    // refresh token が存在しない場合は異常事態なので、例外を投げる
    if (e instanceof RefreshTokenNotExistError) {
      throw e
    }
    // それ以外のエラーはリダイレクト
    redirect()
  })

  headers['Authorization'] = `Bearer ${newAccessToken}`

  const retryResponse = await fetch(url, {
    keepalive,
    method,
    headers,
    ...(data && { body: JSON.stringify(data) }),
  })

  // 成功
  if (retryResponse.ok) {
    return parse(retryResponse)
  }

  // 再度 401 の場合は諦めてリダイレクト
  if (retryResponse.status === 401) {
    redirect()
  }

  // 401 以外は例外
  throw new ApiError(retryResponse)
}

class RefreshTokenNotExistError extends Error {
  constructor() {
    super('refresh token が存在しません')
  }
}

async function refreshToken(): Promise<string> {
  const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN)
  if (!refreshToken) {
    throw new RefreshTokenNotExistError()
  }

  const authResult = await cognitoClient.send(
    new InitiateAuthCommand({
      ClientId: process.env['NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID'],
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: { REFRESH_TOKEN: refreshToken },
    }),
  )

  const newAccessToken = ensure(authResult.AuthenticationResult?.AccessToken)
  localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, newAccessToken)

  return newAccessToken
}
