import { Heading, HeadingProps } from '@chakra-ui/react'
import { FC } from 'react'

const H4: FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading as="h4" fontSize="1rem" fontWeight="bold" lineHeight="140%" letterSpacing="0.64px" {...props}>
      {children}
    </Heading>
  )
}

export default H4
