import { FC } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import SelectionAnswerHistoryListItem from '../SelectionAnswerHistoryListItem/SelectionAnswerHistoryListItem'
import SelectionAnswerHistoryListPresentation from './SelectionAnswerHistoryListPresentation'

const SelectionAnswerHistoryList: FC = () => {
  return (
    <SelectionAnswerHistoryListPresentation
      SelectionAnswerHistoryListItem={SelectionAnswerHistoryListItem}
      useIsMobile={useIsMobile}
    />
  )
}

export default SelectionAnswerHistoryList
