import { FC } from 'react'
import { PublishedVideoSlide } from '../../../orval/loovPublic'
import VideoSlidePlayerPresentation from './VideoSlidePlayerPresentation'

export type VideoSlidePlayerProps = {
  slide: PublishedVideoSlide
}
const VideoSlidePlayer: FC<VideoSlidePlayerProps> = (props) => {
  return <VideoSlidePlayerPresentation {...props} />
}

export default VideoSlidePlayer
