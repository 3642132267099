import { chakra, Image } from '@chakra-ui/react'
import { ComponentProps, FC } from 'react'
import VisualMediaPresentation from './VisualMediaPropsPresentation'

export type VisualMediaProps = ComponentProps<typeof Image> & ComponentProps<typeof chakra.video>

const VisualMedia: FC<VisualMediaProps> = (props) => {
  return <VisualMediaPresentation {...props} Image={Image} />
}

export default VisualMedia
