import { Heading, HeadingProps } from '@chakra-ui/react'
import { FC } from 'react'

const H3: FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading as="h3" fontSize="1.25rem" fontWeight="bold" lineHeight="140%" letterSpacing="0.8px" {...props}>
      {children}
    </Heading>
  )
}

export default H3
