import { Box, HStack, Icon, StackDivider, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { RxTriangleRight } from 'react-icons/rx'
import H3 from '../../elements/typography/H3'
import H4 from '../../elements/typography/H4'
import useIsMobile from '../../hooks/useIsMobile'
import useScenarioPlayer from '../needsTests/ScenarioPlayer/useScenarioPlayer'
import SelectionAnswerHistoryListItem from '../SelectionAnswerHistoryListItem/SelectionAnswerHistoryListItem'

export type SelectionAnswerHistoryListPresentationProps = {
  SelectionAnswerHistoryListItem: typeof SelectionAnswerHistoryListItem
  useIsMobile: typeof useIsMobile
}

const ITEM_WIDTH_PC = 240 //px
const ITEM_WIDTH_MOBILE = 320 //px

const SelectionAnswerHistoryListPresentation: FC<SelectionAnswerHistoryListPresentationProps> = ({
  SelectionAnswerHistoryListItem,
  useIsMobile,
}) => {
  const isMobile = useIsMobile()
  const SelectionHistoryStack = isMobile ? VStack : HStack
  const TitleHeader = isMobile ? H4 : H3
  const { selectionAnswerHistory, publishedScenario, goBackToSelectionSlide } = useScenarioPlayer()

  const showSelectionAnswerHistory = publishedScenario?.showSelectionAnswerHistory && selectionAnswerHistory?.length > 0
  if (!showSelectionAnswerHistory) return null

  return (
    <VStack
      alignItems="start"
      data-testid="selection-answer-history-list-box"
      overflow="auto"
      width="100%"
      paddingX={4}
      paddingTop={4}
      paddingBottom={5}
      background="background"
      rounded="lg"
    >
      <TitleHeader>設問回答の履歴</TitleHeader>
      <SelectionHistoryStack
        divider={
          <StackDivider border={0} alignContent="center" textAlign="center">
            {isMobile ? null : <Icon as={RxTriangleRight} boxSize={8} />}
          </StackDivider>
        }
      >
        {selectionAnswerHistory?.map((selectionAnswerHistoryItem, index) => (
          <Box key={index} width={isMobile ? ITEM_WIDTH_MOBILE : ITEM_WIDTH_PC}>
            <SelectionAnswerHistoryListItem
              historyIndex={index}
              selectionAnswerHistoryItem={selectionAnswerHistoryItem}
              goBackToSelectionSlide={goBackToSelectionSlide}
            />
          </Box>
        ))}
      </SelectionHistoryStack>
    </VStack>
  )
}

export default SelectionAnswerHistoryListPresentation
