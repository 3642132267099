import { Box, CloseButton } from '@chakra-ui/react'
import { FC } from 'react'
import SwrKey from '../commons/swrKey'
import useSuspenseSwr, { immutableOptions } from '../hooks/useSuspenseSwr'

import { getScenarioMobileThumbnail } from '../orval/loovPublic'
import { EmbeddedMobileCustomFloatProps } from './EmbeddedMobileCustomFloat'
import VisualMedia from './VisualMediaProps'

const OFFSET = {
  top_left: { top: '25%', left: 0 },
  top_right: { top: '25%', right: 0 },
  side_left: { top: '50%', left: 0 },
  side_right: { top: '50%', right: 0 },
  bottom_left: { top: '75%', left: 0 },
  bottom_right: { top: '75%', right: 0 },
} as const

export type EmbeddedMobileCustomFloatPresentationProps = EmbeddedMobileCustomFloatProps & {
  getScenarioMobileThumbnail: typeof getScenarioMobileThumbnail
  VisualMedia: typeof VisualMedia
}

const EmbeddedMobileCustomFloatPresentation: FC<EmbeddedMobileCustomFloatPresentationProps> = ({
  scenarioMobileThumbnailId,
  position,
  onClick,
  onClose,
  getScenarioMobileThumbnail,
  VisualMedia,
}) => {
  const {
    data: { url },
  } = useSuspenseSwr(
    [SwrKey.SCENARIO_MOBILE_THUMBNAILS, scenarioMobileThumbnailId],
    () => getScenarioMobileThumbnail(scenarioMobileThumbnailId),
    immutableOptions,
  )

  return (
    <Box position="fixed" {...OFFSET[position]} transform="translateY(-50%)">
      <button onClick={() => onClick()} aria-label="play video">
        <VisualMedia src={url} w="40px" />
      </button>

      <CloseButton
        pos="absolute"
        rounded="full"
        bg="#CCC"
        top={-2}
        left="50%"
        transform="translate(-50%,-100%)"
        onClick={() => onClose()}
      />
    </Box>
  )
}

export default EmbeddedMobileCustomFloatPresentation
