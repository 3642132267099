export const LocalStorageKey = {
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  SWITCH_TENANT_ID: 'switch-tenant-id',
  LEAD_ID: 'lead-id',
  RECORDING_SPEAKER_TYPE: 'recording-speaker-type',
  TEXT_TO_SPEECH_VOICE_LANGUAGE_CODE: 'text-to-speech-voice-language-code',
  TEXT_TO_SPEECH_VOICE_NAME: 'text-to-speach-voice-name',
  TEXT_TO_SPEECH_SPEAKING_RATE: 'text-to-speach-speaking-rate',
  TEXT_TO_SPEECH_PITCH: 'text-to-speach-pitch',
  TEXT_TO_SPEECH_VOLUME_GAIN_DB: 'text-to-speach-volume-gain-db',
  VIDEO_AVATOR_ID: 'video-avator-id',
  FLOW_POSITIONS: 'flow-positions',
  AUDIO_INPUT_DEVICE_ID: 'audio-input-device-id',
  VIDEO_INPUT_DEVICE_ID: 'video-input-device-id',
} as const
type LocalStorageKey = (typeof LocalStorageKey)[keyof typeof LocalStorageKey]
