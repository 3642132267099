import { Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'

const Caption: FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text fontSize="0.75rem" fontWeight="medium" lineHeight="140%" letterSpacing="0.48px" {...props}>
      {children}
    </Text>
  )
}

export default Caption
