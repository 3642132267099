import { chakra, Image } from '@chakra-ui/react'
import { ComponentProps, FC, useMemo } from 'react'
import VisualMediaPresentation from './VisualMediaPresentation'

export type VisualMediaProps = {
  commonProps?: ComponentProps<typeof Image> & ComponentProps<typeof chakra.video>
  imageProps?: ComponentProps<typeof Image>
  videoProps?: ComponentProps<typeof chakra.video>
}

const VisualMedia: FC<VisualMediaProps> = (props) => {
  const createElement = useMemo(() => document.createElement.bind(document), [])

  return <VisualMediaPresentation {...props} Image={Image} createElement={createElement} />
}

export default VisualMedia
