/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * loov-api
 */
import { loovPublicEndPointClient } from './loovClient'
export type GetLatestPublishedScenarioParams = {
  scenarioId: string
}

export type GetLead308 = {
  message: string
}

export type WithUrlScenarioMobileThumbnailAllOf = {
  url: Url
}

export interface ScenarioMobileThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUrlScenarioMobileThumbnailAllOf

/**
 * @nullable
 */
export type PublishedScenarioDetailAllOfScenarioThumbnail = WithUrlScenarioThumbnail | null

/**
 * @nullable
 */
export type PublishedScenarioDetailAllOfScenarioLogo = WithUrlScenarioLogo | null

export type ComplexPublishedSlide = PublishedVideoSlide | PublishedSelectionSlide | PublishedFormSlide

export type PublishedScenarioDetailAllOf = {
  publishedSlides: ComplexPublishedSlide[]
  /** @nullable */
  scenarioLogo: PublishedScenarioDetailAllOfScenarioLogo
  /** @nullable */
  scenarioThumbnail: PublishedScenarioDetailAllOfScenarioThumbnail
}

export type PublishedScenarioDetail = PublishedScenario & PublishedScenarioDetailAllOf

/**
 */
export type PublishedFormSlideAllOfType = (typeof PublishedFormSlideAllOfType)[keyof typeof PublishedFormSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfType = {
  FORM: 'FORM',
} as const

/**
 * @nullable
 */
export type PublishedFormSlideAllOfPublishedVideoSlideContent =
  | (typeof PublishedFormSlideAllOfPublishedVideoSlideContent)[keyof typeof PublishedFormSlideAllOfPublishedVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfPublishedVideoSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedFormSlideAllOfPublishedSelectionSlideContent =
  | (typeof PublishedFormSlideAllOfPublishedSelectionSlideContent)[keyof typeof PublishedFormSlideAllOfPublishedSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedFormSlideAllOfPublishedSelectionSlideContent = {} as const

export type PublishedFormSlideAllOf = {
  publishedFormSlideContent: JoinedPublishedFormSlideContent
  /** @nullable */
  publishedSelectionSlideContent: PublishedFormSlideAllOfPublishedSelectionSlideContent
  /** @nullable */
  publishedVideoSlideContent: PublishedFormSlideAllOfPublishedVideoSlideContent
  /** */
  type: PublishedFormSlideAllOfType
}

export type PublishedFormSlide = PublishedSlide & PublishedFormSlideAllOf

export type JoinedPublishedFormSlideContentAllOf = {
  /** @nullable */
  formThumbnail: JoinedPublishedFormSlideContentAllOfFormThumbnail
  publishedFormInputs: JoinedPublishedFormInput[]
}

export type JoinedPublishedFormSlideContent = PublishedFormSlideContent & JoinedPublishedFormSlideContentAllOf

export type WithUrlFormThumbnailAllOf = {
  url: Url
}

export interface FormThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlFormThumbnail = FormThumbnail & WithUrlFormThumbnailAllOf

/**
 * @nullable
 */
export type JoinedPublishedFormSlideContentAllOfFormThumbnail = WithUrlFormThumbnail | null

export interface PublishedFormInputOption {
  createdAt: string
  formInputOptionId: Cuid
  id: Cuid
  label: string
  publishedFormInputId: Cuid
  updatedAt: string
  value: string
}

export type JoinedPublishedFormInputAllOf = {
  options: PublishedFormInputOption[]
}

export type JoinedPublishedFormInput = PublishedFormInput & JoinedPublishedFormInputAllOf

export type HubspotCreateContactProperty =
  (typeof HubspotCreateContactProperty)[keyof typeof HubspotCreateContactProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HubspotCreateContactProperty = {
  company: 'company',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  numemployees: 'numemployees',
  phone: 'phone',
  state: 'state',
  city: 'city',
  jobtitle: 'jobtitle',
} as const

/**
 * @nullable
 */
export type PublishedFormInputHubspotContactProperty = HubspotCreateContactProperty | null

export interface PublishedFormInput {
  createdAt: string
  formInputId: Cuid
  /** @nullable */
  hubspotContactProperty: PublishedFormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  publishedFormSlideContentId: Cuid
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PublishedFormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

export type SalesforceCreateLeadProperty =
  (typeof SalesforceCreateLeadProperty)[keyof typeof SalesforceCreateLeadProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesforceCreateLeadProperty = {
  Company: 'Company',
  Email: 'Email',
  FirstName: 'FirstName',
  LastName: 'LastName',
  NumberOfEmployees: 'NumberOfEmployees',
  Phone: 'Phone',
  State: 'State',
  City: 'City',
  Title: 'Title',
} as const

/**
 * @nullable
 */
export type PublishedFormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

export type FormInputType = (typeof FormInputType)[keyof typeof FormInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormInputType = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
} as const

/**
 * @nullable
 */
export type PublishedFormSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedFormSlideContentNextPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type PublishedFormSlideContentFormThumbnailId = Cuid | null

export type Email = string

export type FormDesign = (typeof FormDesign)[keyof typeof FormDesign]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormDesign = {
  DEFAULT: 'DEFAULT',
  WITH_THUMBNAIL: 'WITH_THUMBNAIL',
} as const

export interface PublishedFormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: PublishedFormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedFormSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedFormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  publishedSlideId: Cuid
  salesforceCampaignId: string
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

export type PublishedSelectionSlide = PublishedSlide & PublishedSelectionSlideAllOf

/**
 */
export type PublishedSelectionSlideAllOfType =
  (typeof PublishedSelectionSlideAllOfType)[keyof typeof PublishedSelectionSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfType = {
  SELECTION: 'SELECTION',
} as const

/**
 * @nullable
 */
export type PublishedSelectionSlideAllOfPublishedVideoSlideContent =
  | (typeof PublishedSelectionSlideAllOfPublishedVideoSlideContent)[keyof typeof PublishedSelectionSlideAllOfPublishedVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfPublishedVideoSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedSelectionSlideAllOfPublishedFormSlideContent =
  | (typeof PublishedSelectionSlideAllOfPublishedFormSlideContent)[keyof typeof PublishedSelectionSlideAllOfPublishedFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedSelectionSlideAllOfPublishedFormSlideContent = {} as const

export type JoinedPublishedSelectionSlideContentAllOf = {
  publishedSelectionOptions: PublishedSelectionOption[]
}

export type JoinedPublishedSelectionSlideContent = PublishedSelectionSlideContent &
  JoinedPublishedSelectionSlideContentAllOf

export type PublishedSelectionSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: PublishedSelectionSlideAllOfPublishedFormSlideContent
  publishedSelectionSlideContent: JoinedPublishedSelectionSlideContent
  /** @nullable */
  publishedVideoSlideContent: PublishedSelectionSlideAllOfPublishedVideoSlideContent
  /** */
  type: PublishedSelectionSlideAllOfType
}

/**
 * @nullable
 */
export type PublishedSelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedSelectionOptionNextPublishedSlideId = Cuid | null

export interface PublishedSelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedSelectionOptionNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedSelectionOptionNextUrl
  position: number
  publishedSelectionSlideContentId: Cuid
  selectionOptionId: Cuid
  updatedAt: string
  value: string
}

export type SelectionSlideDirection = (typeof SelectionSlideDirection)[keyof typeof SelectionSlideDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideDirection = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
} as const

export interface PublishedSelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  publishedSlideId: Cuid
  text: string
  updatedAt: string
}

export type PublishedVideoSlide = PublishedSlide & PublishedVideoSlideAllOf

/**
 */
export type PublishedVideoSlideAllOfType =
  (typeof PublishedVideoSlideAllOfType)[keyof typeof PublishedVideoSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfType = {
  VIDEO: 'VIDEO',
} as const

/**
 * @nullable
 */
export type PublishedVideoSlideAllOfPublishedSelectionSlideContent =
  | (typeof PublishedVideoSlideAllOfPublishedSelectionSlideContent)[keyof typeof PublishedVideoSlideAllOfPublishedSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfPublishedSelectionSlideContent = {} as const

/**
 * @nullable
 */
export type PublishedVideoSlideAllOfPublishedFormSlideContent =
  | (typeof PublishedVideoSlideAllOfPublishedFormSlideContent)[keyof typeof PublishedVideoSlideAllOfPublishedFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishedVideoSlideAllOfPublishedFormSlideContent = {} as const

export type JoinedPublishedVideoSlideContent = PublishedVideoSlideContent & JoinedPublishedVideoSlideContentAllOf

export type PublishedVideoSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: PublishedVideoSlideAllOfPublishedFormSlideContent
  /** @nullable */
  publishedSelectionSlideContent: PublishedVideoSlideAllOfPublishedSelectionSlideContent
  publishedVideoSlideContent: JoinedPublishedVideoSlideContent
  /** */
  type: PublishedVideoSlideAllOfType
}

export interface VideoCaptionSegment {
  createdAt: string
  end: number
  id: Cuid
  start: number
  text: string
  updatedAt: string
  videoId: Cuid
}

export type JoinedPublishedVideoSlideContentAllOfVideoAllOf = {
  caption: VideoCaptionSegment[]
}

/**
 * @nullable
 */
export type JoinedPublishedVideoSlideContentAllOfVideo =
  | (VideoWithUrl & JoinedPublishedVideoSlideContentAllOfVideoAllOf)
  | null

export type JoinedPublishedVideoSlideContentAllOf = {
  /** @nullable */
  video: JoinedPublishedVideoSlideContentAllOfVideo
}

export type ErrorVideo = VideoBase & ErrorVideoAllOf

/**
 */
export type ErrorVideoAllOfStatus = (typeof ErrorVideoAllOfStatus)[keyof typeof ErrorVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorVideoAllOfStatus = {
  ERROR: 'ERROR',
} as const

/**
 * @nullable
 */
export type ErrorVideoAllOfDuration = (typeof ErrorVideoAllOfDuration)[keyof typeof ErrorVideoAllOfDuration] | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorVideoAllOfDuration = {} as const

export type ErrorVideoAllOf = {
  /** @nullable */
  duration: ErrorVideoAllOfDuration
  /** */
  status: ErrorVideoAllOfStatus
}

/**
 */
export type RecordingVideoAllOfStatus = (typeof RecordingVideoAllOfStatus)[keyof typeof RecordingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordingVideoAllOfStatus = {
  RECORDING: 'RECORDING',
} as const

/**
 * @nullable
 */
export type RecordingVideoAllOfDuration =
  | (typeof RecordingVideoAllOfDuration)[keyof typeof RecordingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordingVideoAllOfDuration = {} as const

export type RecordingVideoAllOf = {
  /** @nullable */
  duration: RecordingVideoAllOfDuration
  /** */
  status: RecordingVideoAllOfStatus
}

export type RecordingVideo = VideoBase & RecordingVideoAllOf

/**
 */
export type ProcessingVideoAllOfStatus = (typeof ProcessingVideoAllOfStatus)[keyof typeof ProcessingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcessingVideoAllOfStatus = {
  PROCESSING: 'PROCESSING',
} as const

/**
 * @nullable
 */
export type ProcessingVideoAllOfDuration =
  | (typeof ProcessingVideoAllOfDuration)[keyof typeof ProcessingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcessingVideoAllOfDuration = {} as const

export type ProcessingVideoAllOf = {
  /** @nullable */
  duration: ProcessingVideoAllOfDuration
  /** */
  status: ProcessingVideoAllOfStatus
}

export type ProcessingVideo = VideoBase & ProcessingVideoAllOf

export type WithUploadUrlUploadingVideoAllOf = {
  uploadUrl: Url
}

export type WithUploadUrlUploadingVideo = UploadingVideo & WithUploadUrlUploadingVideoAllOf

export type VideoWithUrl =
  | WithThumbnailUrlAvailableVideo
  | WithUploadUrlUploadingVideo
  | ProcessingVideo
  | RecordingVideo
  | ErrorVideo

/**
 */
export type UploadingVideoAllOfStatus = (typeof UploadingVideoAllOfStatus)[keyof typeof UploadingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadingVideoAllOfStatus = {
  UPLOADING: 'UPLOADING',
} as const

/**
 * @nullable
 */
export type UploadingVideoAllOfDuration =
  | (typeof UploadingVideoAllOfDuration)[keyof typeof UploadingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadingVideoAllOfDuration = {} as const

export type UploadingVideoAllOf = {
  /** @nullable */
  duration: UploadingVideoAllOfDuration
  /** */
  status: UploadingVideoAllOfStatus
}

export type WithThumbnailUrlAvailableVideoAllOf = {
  animatedThumbnailUrl: Url
  thumbnailUrl: Url
}

export type WithUrlAvailableVideoAllOf = {
  url: Url
}

export type WithUrlAvailableVideo = AvailableVideo & WithUrlAvailableVideoAllOf

export type WithThumbnailUrlAvailableVideo = WithUrlAvailableVideo & WithThumbnailUrlAvailableVideoAllOf

/**
 */
export type AvailableVideoAllOfStatus = (typeof AvailableVideoAllOfStatus)[keyof typeof AvailableVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableVideoAllOfStatus = {
  AVAILABLE: 'AVAILABLE',
} as const

export type AvailableVideoAllOf = {
  duration: number
  /** */
  status: AvailableVideoAllOfStatus
}

export interface VideoBase {
  createdAt: string
  /** @nullable */
  duration: number | null
  id: Cuid
  name: string
  status: VideoStatus
  tenantId: Cuid
  updatedAt: string
  /** @nullable */
  videoRecording: VideoBaseVideoRecording
}

export type UploadingVideo = VideoBase & UploadingVideoAllOf

export type AvailableVideo = VideoBase & AvailableVideoAllOf

/**
 * @nullable
 */
export type VideoRecordingVideoAvatorId = Cuid | null

export type FaceIconLocationType = (typeof FaceIconLocationType)[keyof typeof FaceIconLocationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaceIconLocationType = {
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
} as const

export type SpeakerType = (typeof SpeakerType)[keyof typeof SpeakerType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpeakerType = {
  USER: 'USER',
  MACHINE: 'MACHINE',
} as const

export interface VideoRecording {
  createdAt: string
  faceIconLocationType: FaceIconLocationType
  id: Cuid
  pitch: number
  speakerType: SpeakerType
  speakingRate: number
  updatedAt: string
  /** @nullable */
  videoAvatorId: VideoRecordingVideoAvatorId
  videoId: Cuid
  voiceLanguageCode: string
  voiceName: string
  volumeGainDb: number
}

/**
 * @nullable
 */
export type VideoBaseVideoRecording = VideoRecording | null

export type VideoStatus = (typeof VideoStatus)[keyof typeof VideoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  AVAILABLE: 'AVAILABLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
  RECORDING: 'RECORDING',
} as const

/**
 * @nullable
 */
export type PublishedVideoSlideContentVideoId = Cuid | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextPublishedSlideId = Cuid | null

export interface PublishedVideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedVideoSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedVideoSlideContentNextUrl
  publishedSlideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: PublishedVideoSlideContentVideoId
}

export type SlideType = (typeof SlideType)[keyof typeof SlideType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideType = {
  VIDEO: 'VIDEO',
  SELECTION: 'SELECTION',
  FORM: 'FORM',
} as const

export interface PublishedSlide {
  createdAt: string
  id: Cuid
  name: string
  position: number
  publishedScenarioId: Cuid
  slideId: Cuid
  type: SlideType
  updatedAt: string
}

export type WithUrlScenarioThumbnailAllOf = {
  url: Url
}

export interface ScenarioThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioThumbnail = ScenarioThumbnail & WithUrlScenarioThumbnailAllOf

export type WithUrlScenarioLogoAllOf = {
  url: Url
}

export interface ScenarioLogo {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioLogo = ScenarioLogo & WithUrlScenarioLogoAllOf

/**
 * @nullable
 */
export type PublishedScenarioScenarioThumbnailId = Cuid | null

/**
 * @nullable
 */
export type PublishedScenarioScenarioLogoId = Cuid | null

export type Url = string

export type ScenarioType = (typeof ScenarioType)[keyof typeof ScenarioType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioType = {
  PAGE: 'PAGE',
  EMBEDDED: 'EMBEDDED',
} as const

export interface PublishedScenario {
  createdAt: string
  ctaColor: string
  ctaFontSize: number
  ctaText: string
  ctaUrl: Url
  disableViewerTracking: boolean
  googleAnalyticsMeasurementId: string
  id: Cuid
  introductionCompanyName: string
  introductionDescription: string
  introductionTitle: string
  isAutoPlayEnabled: boolean
  isGoBackButtonEnabled: boolean
  modalDescription: string
  name: string
  scenarioId: Cuid
  /** @nullable */
  scenarioLogoId: PublishedScenarioScenarioLogoId
  scenarioPassword: string
  /** @nullable */
  scenarioThumbnailId: PublishedScenarioScenarioThumbnailId
  showSelectionAnswerHistory: boolean
  tenantId: Cuid
  type: ScenarioType
  updatedAt: string
}

export type CreateLeadSessionInputAllOf = {
  notify?: boolean
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickLeadSessionLeadIdOrPublishedScenarioId {
  leadId: Cuid
  publishedScenarioId: Cuid
}

export type CreateLeadSessionInput = PickLeadSessionLeadIdOrPublishedScenarioId & CreateLeadSessionInputAllOf

export interface LeadSession {
  createdAt: string
  id: Cuid
  isRewatch: boolean
  leadId: Cuid
  publishedScenarioId: Cuid
  updatedAt: string
}

/**
 */
export type CreateFormAnswerEventInputType =
  (typeof CreateFormAnswerEventInputType)[keyof typeof CreateFormAnswerEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateFormAnswerEventInputType = {
  FORM_ANSWER: 'FORM_ANSWER',
} as const

export interface CreateFormAnswerEventInput {
  formValues: FormValue[]
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateFormAnswerEventInputType
}

export type FormValueAllOfTwoAnyOfTwo = {
  publishedFormInputOptionIds: string[]
}

export type FormValueAllOfTwoAnyOf = {
  text: string
}

export type FormValueAllOfTwo = FormValueAllOfTwoAnyOf | FormValueAllOfTwoAnyOfTwo

export type FormValueAllOf = {
  publishedFormInputId: string
}

export type FormValue = FormValueAllOf & FormValueAllOfTwo

/**
 */
export type CreateSelectionAnswerEventInputType =
  (typeof CreateSelectionAnswerEventInputType)[keyof typeof CreateSelectionAnswerEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSelectionAnswerEventInputType = {
  SELECTION_ANSWER: 'SELECTION_ANSWER',
} as const

export interface CreateSelectionAnswerEventInput {
  leadSessionId: string
  publishedSelectionOptionId: string
  /** */
  type: CreateSelectionAnswerEventInputType
}

/**
 */
export type CreateVideoViewingTimeEventInputType =
  (typeof CreateVideoViewingTimeEventInputType)[keyof typeof CreateVideoViewingTimeEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateVideoViewingTimeEventInputType = {
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface CreateVideoViewingTimeEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateVideoViewingTimeEventInputType
  viewingTime: number
}

export type CreateLeadSessionEventInput =
  | CreateSlideViewStartEventInput
  | CreateSlideViewEndEventInput
  | CreateVideoViewingTimeEventInput
  | CreateSelectionAnswerEventInput
  | CreateFormAnswerEventInput

/**
 */
export type CreateSlideViewEndEventInputType =
  (typeof CreateSlideViewEndEventInputType)[keyof typeof CreateSlideViewEndEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSlideViewEndEventInputType = {
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
} as const

export interface CreateSlideViewEndEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateSlideViewEndEventInputType
  viewingTime?: number
}

/**
 */
export type CreateSlideViewStartEventInputType =
  (typeof CreateSlideViewStartEventInputType)[keyof typeof CreateSlideViewStartEventInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSlideViewStartEventInputType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
} as const

export interface CreateSlideViewStartEventInput {
  leadSessionId: string
  publishedSlideId: string
  /** */
  type: CreateSlideViewStartEventInputType
}

/**
 * @nullable
 */
export type LeadSessionEventPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type LeadSessionEventPublishedSelectionOptionId = Cuid | null

export type LeadSessionEventType = (typeof LeadSessionEventType)[keyof typeof LeadSessionEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeadSessionEventType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
  SELECTION_ANSWER: 'SELECTION_ANSWER',
  FORM_ANSWER: 'FORM_ANSWER',
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface LeadSessionEvent {
  createdAt: string
  id: Cuid
  leadSessionId: Cuid
  /** @nullable */
  publishedSelectionOptionId: LeadSessionEventPublishedSelectionOptionId
  /** @nullable */
  publishedSlideId: LeadSessionEventPublishedSlideId
  /** @nullable */
  text: string | null
  type: LeadSessionEventType
  updatedAt: string
  /** @nullable */
  viewingTime: number | null
}

export interface MergeLeadInput {
  destinationLeadId: string
}

export type LeadDetailAllOf = {
  answeredFormSlideIds: string[]
}

export interface Lead {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type LeadDetail = Lead & LeadDetailAllOf

/**
 * @nullable
 */
export type GetEmbedScriptOutputAllOfFloatConfig = EmbedScriptFloatConfig | null

export type GetEmbedScriptOutputAllOf = {
  /** @nullable */
  floatConfig: GetEmbedScriptOutputAllOfFloatConfig
}

export type GetEmbedScriptOutput = EmbedScript & GetEmbedScriptOutputAllOf

/**
 * @nullable
 */
export type EmbedScriptFloatConfigScenarioMobileThumbnailId = Cuid | null

export type MobileDisplayType = (typeof MobileDisplayType)[keyof typeof MobileDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  SIDE_LEFT: 'SIDE_LEFT',
  SIDE_RIGHT: 'SIDE_RIGHT',
  CUSTOM_TOP_LEFT: 'CUSTOM_TOP_LEFT',
  CUSTOM_TOP_RIGHT: 'CUSTOM_TOP_RIGHT',
  CUSTOM_SIDE_LEFT: 'CUSTOM_SIDE_LEFT',
  CUSTOM_SIDE_RIGHT: 'CUSTOM_SIDE_RIGHT',
  CUSTOM_BOTTOM_LEFT: 'CUSTOM_BOTTOM_LEFT',
  CUSTOM_BOTTOM_RIGHT: 'CUSTOM_BOTTOM_RIGHT',
  NONE: 'NONE',
} as const

export type DesktopDisplayHorizontalOffsetType =
  (typeof DesktopDisplayHorizontalOffsetType)[keyof typeof DesktopDisplayHorizontalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayHorizontalOffsetType = {
  FROM_LEFT: 'FROM_LEFT',
  FROM_RIGHT: 'FROM_RIGHT',
} as const

export type DesktopDisplayVerticalOffsetType =
  (typeof DesktopDisplayVerticalOffsetType)[keyof typeof DesktopDisplayVerticalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayVerticalOffsetType = {
  FROM_TOP: 'FROM_TOP',
  FROM_BOTTOM: 'FROM_BOTTOM',
} as const

export type DesktopDisplayType = (typeof DesktopDisplayType)[keyof typeof DesktopDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  CUSTOM_OFFSET: 'CUSTOM_OFFSET',
} as const

export type Cuid = string

export interface EmbedScriptFloatConfig {
  createdAt: string
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  embedScriptId: Cuid
  id: Cuid
  mobileDisplayType: MobileDisplayType
  scenarioId: Cuid
  /** @nullable */
  scenarioMobileThumbnailId: EmbedScriptFloatConfigScenarioMobileThumbnailId
  updatedAt: string
}

export interface EmbedScript {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export const getEmbedScript = (embedScriptId: Cuid) => {
  return loovPublicEndPointClient<GetEmbedScriptOutput>({
    url: `/public/embed-scripts/${embedScriptId}`,
    method: 'POST',
  })
}

export const createLead = () => {
  return loovPublicEndPointClient<LeadDetail>({ url: `/public/leads`, method: 'POST' })
}

export const getLead = (leadId: Cuid) => {
  return loovPublicEndPointClient<LeadDetail>({ url: `/public/leads/${leadId}`, method: 'GET' })
}

export const setLeadProfileToLead = (leadId: Cuid, leadProfileId: Cuid) => {
  return loovPublicEndPointClient<void>({
    url: `/public/leads/${leadId}/lead-profiles/${leadProfileId}`,
    method: 'PUT',
  })
}

export const mergeLead = (leadId: Cuid, mergeLeadInput: MergeLeadInput) => {
  return loovPublicEndPointClient<void>({
    url: `/public/leads/${leadId}/merge`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: mergeLeadInput,
  })
}

export const createLeadSessionEvent = (createLeadSessionEventInput: CreateLeadSessionEventInput) => {
  return loovPublicEndPointClient<LeadSessionEvent>({
    url: `/public/lead-session-events`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadSessionEventInput,
  })
}

export const createLeadSession = (createLeadSessionInput: CreateLeadSessionInput) => {
  return loovPublicEndPointClient<LeadSession>({
    url: `/public/lead-sessions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadSessionInput,
  })
}

export const getLatestPublishedScenario = (params: GetLatestPublishedScenarioParams) => {
  return loovPublicEndPointClient<PublishedScenarioDetail>({
    url: `/public/published-scenarios/latest`,
    method: 'GET',
    params,
  })
}

export const getScenarioMobileThumbnail = (scenarioMobileThumbnailId: string) => {
  return loovPublicEndPointClient<WithUrlScenarioMobileThumbnail>({
    url: `/public/scenario-mobile-thumbnails/${scenarioMobileThumbnailId}`,
    method: 'GET',
  })
}

export type GetEmbedScriptResult = NonNullable<Awaited<ReturnType<typeof getEmbedScript>>>
export type CreateLeadResult = NonNullable<Awaited<ReturnType<typeof createLead>>>
export type GetLeadResult = NonNullable<Awaited<ReturnType<typeof getLead>>>
export type SetLeadProfileToLeadResult = NonNullable<Awaited<ReturnType<typeof setLeadProfileToLead>>>
export type MergeLeadResult = NonNullable<Awaited<ReturnType<typeof mergeLead>>>
export type CreateLeadSessionEventResult = NonNullable<Awaited<ReturnType<typeof createLeadSessionEvent>>>
export type CreateLeadSessionResult = NonNullable<Awaited<ReturnType<typeof createLeadSession>>>
export type GetLatestPublishedScenarioResult = NonNullable<Awaited<ReturnType<typeof getLatestPublishedScenario>>>
export type GetScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioMobileThumbnail>>>
