/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * loov-api
 */
import {
  default as batchCreateLeadProfilesMutator,
  default as createEmbedScriptMutator,
  default as createFormInputMutator,
  default as createFormInputOptionMutator,
  default as createFormThumbnailMutator,
  default as createLeadProfileMutator,
  default as createScenarioLogoMutator,
  default as createScenarioMobileThumbnailMutator,
  default as createScenarioMutator,
  default as createScenarioThumbnailMutator,
  default as createSelectionOptionMutator,
  default as createSlideMutator,
  default as createUserMutator,
  default as createVideoAvatorMutator,
  default as createVideoMutator,
  default as deleteFormInputMutator,
  default as deleteFormInputOptionMutator,
  default as deleteLeadSessionMutator,
  default as deleteScenarioMutator,
  default as deleteSelectionOptionMutator,
  default as deleteSlideMutator,
  default as deleteUserMutator,
  default as deleteVideoMutator,
  default as exportLeadSessionsMutator,
  default as exportSelectionAnswersSummaryMutator,
  default as getBatchOperationMutator,
  default as getDailyScenarioStatsMutator,
  default as getFormInputMutator,
  default as getFormInputOptionMutator,
  default as getFormSlideContentBySlideIdMutator,
  default as getFormThumbnailMutator,
  default as getLeadSessionDetailMutator,
  default as getMeMutator,
  default as getScenarioDetailMutator,
  default as getScenarioLogoMutator,
  default as getScenarioMobileThumbnailMutator,
  default as getScenarioMutator,
  default as getScenarioStatsSummaryMutator,
  default as getScenarioThumbnailMutator,
  default as getSelectionOptionMutator,
  default as getSelectionSlideContentBySlideIdMutator,
  default as getSlideMutator,
  default as getTenantMutator,
  default as getVideoAvatorMutator,
  default as getVideoCaptionMutator,
  default as getVideoMutator,
  default as getVideoSlideContentBySlideIdMutator,
  default as listFormInputOptionsMutator,
  default as listFormInputsMutator,
  default as listLeadProfilesMutator,
  default as listLeadSessionsMutator,
  default as listPublishedVideoSlideContentsMutator,
  default as listScenariosMutator,
  default as listSelectionOptionsMutator,
  default as listSlidesMutator,
  default as listStarredScenariosMutator,
  default as listTenantsMutator,
  default as listUsersMutator,
  default as listVideoSlideContentMutator,
  default as listVideosMutator,
  default as publishScenarioMutator,
  default as setVideoCaptionMutator,
  default as starScenarioMutator,
  default as synthesizeSpeechMutator,
  default as unstarScenarioMutator,
  default as updateFormInputMutator,
  default as updateFormInputOptionMutator,
  default as updateFormSlideContentMutator,
  default as updateMeMutator,
  default as updateScenarioMutator,
  default as updateSelectionOptionMutator,
  default as updateSelectionSlideContentMutator,
  default as updateSlideMutator,
  default as updateTenantMutator,
  default as updateUserMutator,
  default as updateVideoMutator,
  default as updateVideoSlideContentMutator,
} from './loovClient'
export type GetVideoSlideContentBySlideIdParams = {
  slideId: Cuid
}

export type ListVideoSlideContentParams = {
  videoId?: Cuid
}

export type GetVideoParams = {
  /**
   * URL の有効期限（秒）
   */
  urlExpiresIn?: number
}

export type ListVideosParams = {
  page?: number
  perPage?: number
}

export type ListSlidesParams = {
  scenarioId: string
}

export type GetSelectionSlideContentBySlideIdParams = {
  slideId: string
}

export type ListSelectionOptionsParams = {
  selectionSlideContentId: string
}

export type GetDailyScenarioStatsParams = {
  from: string
  to: string
}

export type GetScenarioStatsSummaryParams = {
  from: string
  to: string
}

export type ListPublishedVideoSlideContentsParams = {
  videoId?: Cuid
  isLatest?: boolean
}

export type ListLeadSessionsParams = {
  scenarioId: string
  hasAttribute?: boolean
  page?: number
  perPage?: number
}

export type ListLeadProfilesParams = {
  batchOperationId?: string
}

export type GetFormSlideContentBySlideIdParams = {
  slideId: string
}

export type ListFormInputsParams = {
  formSlideContentId: string
}

export type ListFormInputOptionsParams = {
  formInputId: string
}

export type ExportSelectionAnswersSummaryParams = {
  scenarioId: string
  from?: string
  to?: string
}

export type ExportLeadSessionsParams = {
  scenarioId: string
  hasAttribute?: boolean
  from?: string
  to?: string
}

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlVideoId = Cuid | null

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextUrl = Url | null

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextSlideId = Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl {
  /** @nullable */
  nextSlideId?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextSlideId
  /** @nullable */
  nextUrl?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextUrl
  /** @nullable */
  videoId?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlVideoId
}

export type UpdateVideoSlideContentInput = PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl

export type SetVideoCaptionInputItem = {
  end: number
  start: number
  text: string
}

export type SetVideoCaptionInput = SetVideoCaptionInputItem[]

export type UpdateVideoInputStatus = (typeof UpdateVideoInputStatus)[keyof typeof UpdateVideoInputStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateVideoInputStatus = {
  ERROR: 'ERROR',
  AVAILABLE: 'AVAILABLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
} as const

export interface UpdateVideoInput {
  name?: string
  status?: UpdateVideoInputStatus
}

export type CreateVideoInputConvertOptionsClippingRangesItem = {
  endTimecode: string
  startTimecode: string
}

export type CreateVideoInputConvertOptions = {
  clippingRanges: CreateVideoInputConvertOptionsClippingRangesItem[]
}

export interface CreateVideoInput {
  convertOptions?: CreateVideoInputConvertOptions
  name: string
}

export type WithUploadUrlVideoAndStatusUPLOADING = WithUploadUrlVideoAndStatusUPLOADINGAllOf &
  WithUploadUrlVideoAndStatusUPLOADINGAllOfFour

export type CreateVideoOutput = WithUploadUrlVideoAndStatusUPLOADING

export type WithUploadUrlVideoAndStatusUPLOADINGAllOfFour = {
  uploadUrl: Url
}

/**
 */
export type WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOfStatus =
  (typeof WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOfStatus)[keyof typeof WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOfStatus = {
  UPLOADING: 'UPLOADING',
} as const

export type WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOf = {
  /** */
  status: WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOfStatus
}

export type WithUploadUrlVideoAndStatusUPLOADINGAllOf = Video & WithUploadUrlVideoAndStatusUPLOADINGAllOfAllOf

export type WithUrlVideoAvatorAllOf = {
  url: Url
}

export type WithUploadUrlVideoAvatorAllOf = {
  uploadUrl: Url
}

export interface VideoAvator {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type WithUrlVideoAvator = VideoAvator & WithUrlVideoAvatorAllOf

export type WithUploadUrlVideoAvator = VideoAvator & WithUploadUrlVideoAvatorAllOf

/**
 * Enums
 */
export type Role = (typeof Role)[keyof typeof Role]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  READ_ONLY: 'READ_ONLY',
  READ_WRITE: 'READ_WRITE',
} as const

export interface UpdateUserInput {
  role: Role
}

export type UserAllOf = {
  email: Email
  role: Role
}

export type User = SimpleUser & UserAllOf

export interface CreateUserInput {
  email: Email
  firstName: string
  lastName: string
  role: Role
}

/**
 * @nullable
 */
export type PartialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorIdVideoAvatorId = Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorId {
  email?: Email
  firstName?: string
  lastName?: string
  /** @nullable */
  videoAvatorId?: PartialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorIdVideoAvatorId
}

export type UpdateMeInput = PartialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorId

export type Permission = (typeof Permission)[keyof typeof Permission]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Permission = {
  UPDATE_TENANT: 'UPDATE_TENANT',
  GET_USER: 'GET_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  CREATE_SCENARIO: 'CREATE_SCENARIO',
  UPDATE_SCENARIO: 'UPDATE_SCENARIO',
  DELETE_SCENARIO: 'DELETE_SCENARIO',
} as const

export type MeAllOf = {
  email: Email
  permissions: Permission[]
}

export type Me = SimpleUser & MeAllOf

/**
 * @nullable
 */
export type SimpleUserVideoAvatorId = Cuid | null

export interface SimpleUser {
  createdAt: string
  firstName: string
  id: Cuid
  lastName: string
  tenantId: Cuid
  updatedAt: string
  /** @nullable */
  videoAvatorId: SimpleUserVideoAvatorId
}

export type SynthesizeSpeechInputAllOf = {
  audioConfig?: SynthesizeSpeechInputAllOfAudioConfig
}

export type SynthesizeSpeechInputAllOfAudioConfigAllOfTwo = {
  /**
   * @minimum -20
   * @maximum 20
   */
  pitch?: number
  /**
   * @minimum 0.25
   * @maximum 4
   */
  speakingRate?: number
  /**
   * @minimum -96
   * @maximum 16
   */
  volumeGainDb?: number
}

export type SynthesizeSpeechInputAllOfAudioConfigAllOf = {
  pitch?: number
  speakingRate?: number
  volumeGainDb?: number
}

export type SynthesizeSpeechInputAllOfAudioConfig = SynthesizeSpeechInputAllOfAudioConfigAllOf &
  SynthesizeSpeechInputAllOfAudioConfigAllOfTwo

export type TextToSpeechInputAllOfFourAnyOfTwo = {
  text: string
}

export type TextToSpeechInputAllOfFourAnyOf = {
  ssml: string
}

export type TextToSpeechInputAllOfFour = TextToSpeechInputAllOfFourAnyOf | TextToSpeechInputAllOfFourAnyOfTwo

export type TextToSpeechInput = TextToSpeechInputAllOf & TextToSpeechInputAllOfFour

export type SynthesizeSpeechInput = TextToSpeechInput & SynthesizeSpeechInputAllOf

export type TextToSpeechInputAllOfVoice = {
  languageCode: VoiceLanguageCode
  name: VoiceName
}

export type TextToSpeechInputAllOfAudioConfig = {
  pitch?: number
  speakingRate?: number
  volumeGainDb?: number
}

export type TextToSpeechInputAllOf = {
  audioConfig?: TextToSpeechInputAllOfAudioConfig
  voice: TextToSpeechInputAllOfVoice
}

export type VoiceName = (typeof VoiceName)[keyof typeof VoiceName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VoiceName = {
  'ja-JP-Neural2-B': 'ja-JP-Neural2-B',
  'ja-JP-Neural2-C': 'ja-JP-Neural2-C',
  'ja-JP-Neural2-D': 'ja-JP-Neural2-D',
  'en-US-Neural2-A': 'en-US-Neural2-A',
  'en-US-Neural2-D': 'en-US-Neural2-D',
  'en-US-Neural2-I': 'en-US-Neural2-I',
  'en-US-Neural2-J': 'en-US-Neural2-J',
  'en-US-Neural2-C': 'en-US-Neural2-C',
  'en-US-Neural2-E': 'en-US-Neural2-E',
  'en-US-Neural2-F': 'en-US-Neural2-F',
  'en-US-Neural2-G': 'en-US-Neural2-G',
  'en-US-Neural2-H': 'en-US-Neural2-H',
  'en-GB-Neural2-B': 'en-GB-Neural2-B',
  'en-GB-Neural2-D': 'en-GB-Neural2-D',
  'en-GB-Neural2-A': 'en-GB-Neural2-A',
  'en-GB-Neural2-C': 'en-GB-Neural2-C',
  'en-GB-Neural2-F': 'en-GB-Neural2-F',
} as const

export type VoiceLanguageCode = (typeof VoiceLanguageCode)[keyof typeof VoiceLanguageCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VoiceLanguageCode = {
  'ja-JP': 'ja-JP',
  'en-US': 'en-US',
  'en-GB': 'en-GB',
} as const

/**
 * Make all properties in T optional
 */
export interface PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmail {
  hubspotAccessToken?: string
  hubspotNotificationEmail?: Email
  isHubspotIntegrationEnabled?: boolean
  isSalesforceIntegrationEnabled?: boolean
  name?: string
  salesforceClientId?: string
  salesforceClientSecret?: string
  salesforceMyDomainName?: string
  salesforceNotificationEmail?: Email
}

export type UpdateTenantInput =
  PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmail

export interface Tenant {
  createdAt: string
  hubspotAccessToken: string
  hubspotNotificationEmail: Email
  id: Cuid
  isHubspotIntegrationEnabled: boolean
  isSalesforceIntegrationEnabled: boolean
  name: string
  salesforceClientId: string
  salesforceClientSecret: string
  salesforceMyDomainName: string
  salesforceNotificationEmail: Email
  updatedAt: string
}

export interface UpdateSlideInput {
  memo?: string
  name?: string
  position?: number
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickSlideScenarioIdOrNameOrTypeOrPosition {
  name: string
  position: number
  scenarioId: Cuid
  type: SlideType
}

export type CreateSlideInput = PickSlideScenarioIdOrNameOrTypeOrPosition

/**
 * Make all properties in T optional
 */
export interface PartialPickSelectionSlideContentTextOrDirection {
  direction?: SelectionSlideDirection
  text?: string
}

export type UpdateSelectionSlideContentInput = PartialPickSelectionSlideContentTextOrDirection

export type UpdateSelectionOptionInput = PartialPickSelectionOptionValueOrNextSlideIdOrNextUrl

/**
 * @nullable
 */
export type PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlNextUrl = Url | null

/**
 * @nullable
 */
export type PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlNextSlideId = Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickSelectionOptionValueOrNextSlideIdOrNextUrl {
  /** @nullable */
  nextSlideId?: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlNextSlideId
  /** @nullable */
  nextUrl?: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlNextUrl
  value?: string
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickSelectionOptionSelectionSlideContentId {
  selectionSlideContentId: Cuid
}

export type CreateSelectionOptionInput = PickSelectionOptionSelectionSlideContentId

export interface CreateScenarioThumbnailInput {
  scenarioId: string
}

export type WithUploadUrlScenarioThumbnailAllOf = {
  uploadUrl: Url
}

export type WithUploadUrlScenarioThumbnail = ScenarioThumbnail & WithUploadUrlScenarioThumbnailAllOf

export type UpdateScenarioInput =
  PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublic

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioThumbnailId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioMobileThumbnailId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioLogoId =
  Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublic {
  ctaColor?: string
  ctaFontSize?: number
  ctaText?: string
  ctaUrl?: Url
  googleAnalyticsMeasurementId?: string
  introductionCompanyName?: string
  introductionDescription?: string
  introductionTitle?: string
  isGoBackButtonEnabled?: boolean
  isPublic?: boolean
  modalDescription?: string
  name?: string
  /** @nullable */
  scenarioLogoId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioLogoId
  /** @nullable */
  scenarioMobileThumbnailId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioMobileThumbnailId
  /** @nullable */
  scenarioThumbnailId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublicScenarioThumbnailId
}

export type DailyScenarioStatsAllOf = {
  date: string
}

export type DailyScenarioStats = ScenarioStats & DailyScenarioStatsAllOf

export interface SelectionOptionStats {
  id: string
  numSelected: number
  position: number
  value: string
}

export interface SlideStats {
  /** @nullable */
  avgWatchVideoTime: number | null
  id: string
  name: string
  numViewComplete: number
  numViewStart: number
  position: number
  /** @nullable */
  selectionOptionStats: SelectionOptionStats[] | null
  type: SlideType
}

export type ScenarioStatsSummaryAllOf = {
  slideStats: SlideStats[]
}

export interface ScenarioStats {
  numFormSubmissions: number
  numViewers: number
  numVisitors: number
}

export type ScenarioStatsSummary = ScenarioStats & ScenarioStatsSummaryAllOf

/**
 * @nullable
 */
export type ScenarioDetailAllOfScenarioLogo = WithUrlScenarioLogo | null

export type ScenarioDetailAllOf = {
  /** @nullable */
  lastPublishedAt: string | null
  /** @nullable */
  scenarioLogo: ScenarioDetailAllOfScenarioLogo
  /** @nullable */
  scenarioThumbnail: ScenarioDetailAllOfScenarioThumbnail
  slides: ComplexSlide[]
}

export type ScenarioDetail = Scenario & ScenarioDetailAllOf

export type WithUrlScenarioThumbnailAllOf = {
  url: Url
}

export interface ScenarioThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioThumbnail = ScenarioThumbnail & WithUrlScenarioThumbnailAllOf

/**
 * @nullable
 */
export type ScenarioDetailAllOfScenarioThumbnail = WithUrlScenarioThumbnail | null

export type FormSlide = Slide & FormSlideAllOf

export type ComplexSlide = VideoSlide | SelectionSlide | FormSlide

/**
 * @nullable
 */
export type FormSlideAllOfVideoSlideContent =
  | (typeof FormSlideAllOfVideoSlideContent)[keyof typeof FormSlideAllOfVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfVideoSlideContent = {} as const

/**
 */
export type FormSlideAllOfType = (typeof FormSlideAllOfType)[keyof typeof FormSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfType = {
  FORM: 'FORM',
} as const

/**
 * @nullable
 */
export type FormSlideAllOfSelectionSlideContent =
  | (typeof FormSlideAllOfSelectionSlideContent)[keyof typeof FormSlideAllOfSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfSelectionSlideContent = {} as const

export type FormSlideAllOf = {
  formSlideContent: JoinedFormSlideContent
  /** @nullable */
  selectionSlideContent: FormSlideAllOfSelectionSlideContent
  /** */
  type: FormSlideAllOfType
  /** @nullable */
  videoSlideContent: FormSlideAllOfVideoSlideContent
}

/**
 * @nullable
 */
export type JoinedFormSlideContentAllOfFormThumbnail = WithUrlFormThumbnail | null

export type JoinedFormSlideContentAllOf = {
  formInputs: JoinedFormSlideContentAllOfFormInputsItem[]
  /** @nullable */
  formThumbnail: JoinedFormSlideContentAllOfFormThumbnail
}

export type JoinedFormSlideContent = FormSlideContent & JoinedFormSlideContentAllOf

export type JoinedFormSlideContentAllOfFormInputsItemAllOf = {
  options: FormInputOption[]
}

export type JoinedFormSlideContentAllOfFormInputsItem = FormInput & JoinedFormSlideContentAllOfFormInputsItemAllOf

export type SelectionSlide = Slide & SelectionSlideAllOf

/**
 * @nullable
 */
export type SelectionSlideAllOfVideoSlideContent =
  | (typeof SelectionSlideAllOfVideoSlideContent)[keyof typeof SelectionSlideAllOfVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfVideoSlideContent = {} as const

/**
 */
export type SelectionSlideAllOfType = (typeof SelectionSlideAllOfType)[keyof typeof SelectionSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfType = {
  SELECTION: 'SELECTION',
} as const

/**
 * @nullable
 */
export type SelectionSlideAllOfFormSlideContent =
  | (typeof SelectionSlideAllOfFormSlideContent)[keyof typeof SelectionSlideAllOfFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfFormSlideContent = {} as const

export type JoinedSelectionSlideContent = SelectionSlideContent & JoinedSelectionSlideContentAllOf

export type SelectionSlideAllOf = {
  /** @nullable */
  formSlideContent: SelectionSlideAllOfFormSlideContent
  selectionSlideContent: JoinedSelectionSlideContent
  /** */
  type: SelectionSlideAllOfType
  /** @nullable */
  videoSlideContent: SelectionSlideAllOfVideoSlideContent
}

/**
 * @nullable
 */
export type SelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type SelectionOptionNextSlideId = Cuid | null

export interface SelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextSlideId: SelectionOptionNextSlideId
  /** @nullable */
  nextUrl: SelectionOptionNextUrl
  selectionSlideContentId: Cuid
  updatedAt: string
  value: string
}

export type JoinedSelectionSlideContentAllOf = {
  selectionOptions: SelectionOption[]
}

export interface SelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  slideId: Cuid
  text: string
  updatedAt: string
}

/**
 */
export type VideoSlideAllOfType = (typeof VideoSlideAllOfType)[keyof typeof VideoSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfType = {
  VIDEO: 'VIDEO',
} as const

/**
 * @nullable
 */
export type VideoSlideAllOfSelectionSlideContent =
  | (typeof VideoSlideAllOfSelectionSlideContent)[keyof typeof VideoSlideAllOfSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfSelectionSlideContent = {} as const

/**
 * @nullable
 */
export type VideoSlideAllOfFormSlideContent =
  | (typeof VideoSlideAllOfFormSlideContent)[keyof typeof VideoSlideAllOfFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfFormSlideContent = {} as const

export type JoinedVideoSlideContent = VideoSlideContent & JoinedVideoSlideContentAllOf

export type VideoSlideAllOf = {
  /** @nullable */
  formSlideContent: VideoSlideAllOfFormSlideContent
  /** @nullable */
  selectionSlideContent: VideoSlideAllOfSelectionSlideContent
  /** */
  type: VideoSlideAllOfType
  videoSlideContent: JoinedVideoSlideContent
}

export type VideoSlide = Slide & VideoSlideAllOf

export interface VideoCaptionSegment {
  createdAt: string
  end: number
  id: Cuid
  start: number
  text: string
  updatedAt: string
  videoId: Cuid
}

export type JoinedVideoSlideContentAllOfVideoAllOf = {
  caption: VideoCaptionSegment[]
}

/**
 * @nullable
 */
export type JoinedVideoSlideContentAllOfVideo = (VideoWithUrl & JoinedVideoSlideContentAllOfVideoAllOf) | null

export type JoinedVideoSlideContentAllOf = {
  /** @nullable */
  video: JoinedVideoSlideContentAllOfVideo
}

export type WithThumbnailUrlAvailableVideoAllOf = {
  animatedThumbnailUrl: Url
  thumbnailUrl: Url
}

export type WithUrlAvailableVideoAllOf = {
  url: Url
}

export type WithUrlAvailableVideo = AvailableVideo & WithUrlAvailableVideoAllOf

export type WithThumbnailUrlAvailableVideo = WithUrlAvailableVideo & WithThumbnailUrlAvailableVideoAllOf

export type VideoWithUrl = WithThumbnailUrlAvailableVideo | UnAvailableVideo

/**
 * @nullable
 */
export type VideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type VideoSlideContentNextSlideId = Cuid | null

export interface Slide {
  createdAt: string
  id: Cuid
  memo: string
  name: string
  position: number
  scenarioId: Cuid
  type: SlideType
  updatedAt: string
}

export type CopyScenarioInputAllOfCopyOptions = {
  sourceId: Cuid
}

export type CopyScenarioInputAllOf = {
  copyOptions: CopyScenarioInputAllOfCopyOptions
}

export type CopyScenarioInput = PickScenarioNameOrType & CopyScenarioInputAllOf

export type CreateScenarioInput = CreateScenarioFromScratchInput | CopyScenarioInput

export type CreateScenarioSlideInput =
  | CreateScenarioVideoSlideInput
  | CreateScenarioSelectionSlideInput
  | CreateScenarioFormSlideInput

export type CreateScenarioFromScratchInputAllOf = {
  slides?: CreateScenarioSlideInput[]
}

export type CreateScenarioFromScratchInput = PickScenarioNameOrType & CreateScenarioFromScratchInputAllOf

/**
 */
export type CreateScenarioFormSlideInputType =
  (typeof CreateScenarioFormSlideInputType)[keyof typeof CreateScenarioFormSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioFormSlideInputType = {
  FORM: 'FORM',
} as const

export type CreateScenarioFormSlideInputFormInputsItem = {
  name: string
  placeholder: string
  required: boolean
  type: FormInputType
}

export interface CreateScenarioFormSlideInput {
  buttonText?: string
  description?: string
  design?: FormDesign
  formInputs?: CreateScenarioFormSlideInputFormInputsItem[]
  name: string
  nextSlideIndex?: number
  nextUrl?: string
  /** */
  type: CreateScenarioFormSlideInputType
}

/**
 */
export type CreateScenarioSelectionSlideInputType =
  (typeof CreateScenarioSelectionSlideInputType)[keyof typeof CreateScenarioSelectionSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioSelectionSlideInputType = {
  SELECTION: 'SELECTION',
} as const

export type CreateScenarioSelectionSlideInputOptionsItem = {
  nextSlideIndex?: number
  nextUrl?: string
  value?: string
}

export interface CreateScenarioSelectionSlideInput {
  name: string
  options?: CreateScenarioSelectionSlideInputOptionsItem[]
  text?: string
  /** */
  type: CreateScenarioSelectionSlideInputType
}

/**
 */
export type CreateScenarioVideoSlideInputType =
  (typeof CreateScenarioVideoSlideInputType)[keyof typeof CreateScenarioVideoSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioVideoSlideInputType = {
  VIDEO: 'VIDEO',
} as const

export interface CreateScenarioVideoSlideInput {
  name: string
  nextSlideIndex?: number
  nextUrl?: string
  /** */
  type: CreateScenarioVideoSlideInputType
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickScenarioNameOrType {
  name: string
  type: ScenarioType
}

export type CreateScenarioInputBase = PickScenarioNameOrType

/**
 * @nullable
 */
export type ScenarioScenarioThumbnailId = Cuid | null

/**
 * @nullable
 */
export type ScenarioScenarioMobileThumbnailId = Cuid | null

/**
 * @nullable
 */
export type ScenarioScenarioLogoId = Cuid | null

export type ScenarioType = (typeof ScenarioType)[keyof typeof ScenarioType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioType = {
  PAGE: 'PAGE',
  EMBEDDED: 'EMBEDDED',
} as const

export interface Scenario {
  contentUpdatedAt: string
  createdAt: string
  ctaColor: string
  ctaFontSize: number
  ctaText: string
  ctaUrl: Url
  googleAnalyticsMeasurementId: string
  id: Cuid
  introductionCompanyName: string
  introductionDescription: string
  introductionTitle: string
  isGoBackButtonEnabled: boolean
  isPublic: boolean
  /** @nullable */
  lastPublishedAt: string | null
  modalDescription: string
  name: string
  /** @nullable */
  scenarioLogoId: ScenarioScenarioLogoId
  /** @nullable */
  scenarioMobileThumbnailId: ScenarioScenarioMobileThumbnailId
  /** @nullable */
  scenarioThumbnailId: ScenarioScenarioThumbnailId
  tenantId: Cuid
  type: ScenarioType
  updatedAt: string
}

export type WithUrlScenarioMobileThumbnailAllOf = {
  url: Url
}

export type WithUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUrlScenarioMobileThumbnailAllOf

export interface CreateScenarioMobileThumbnailInput {
  scenarioId: string
}

export type WithUploadUrlScenarioMobileThumbnailAllOf = {
  uploadUrl: Url
}

export interface ScenarioMobileThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUploadUrlScenarioMobileThumbnailAllOf

export type WithUrlScenarioLogoAllOf = {
  url: Url
}

export type WithUrlScenarioLogo = ScenarioLogo & WithUrlScenarioLogoAllOf

export interface CreateScenarioLogoInput {
  scenarioId: string
}

export type WithUploadUrlScenarioLogoAllOf = {
  uploadUrl: Url
}

export interface ScenarioLogo {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlScenarioLogo = ScenarioLogo & WithUploadUrlScenarioLogoAllOf

export type LeadSessionEventDetailAllOf = {
  formAnswers: LeadSessionEventDetailAllOfFormAnswersItem[]
  /** @nullable */
  publishedSelectionOption: LeadSessionEventDetailAllOfPublishedSelectionOption
  /** @nullable */
  publishedSlide: LeadSessionEventDetailAllOfPublishedSlide
}

export type LeadSessionEventDetail = LeadSessionEvent & LeadSessionEventDetailAllOf

export type LeadSessionDetailAllOf = {
  leadSessionEvents: LeadSessionEventDetail[]
}

export type LeadSessionDetail = LeadSession & LeadSessionDetailAllOf

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOfVideo = Video | null

export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOf = {
  /** @nullable */
  video: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOfVideo
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContent =
  | (PublishedVideoSlideContent & LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOf)
  | null

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedFormSlideContent = PublishedFormSlideContent | null

export type LeadSessionEventDetailAllOfPublishedSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedFormSlideContent
  /** @nullable */
  publishedVideoSlideContent: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContent
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlide =
  | (PublishedSlide & LeadSessionEventDetailAllOfPublishedSlideAllOf)
  | null

export type LeadSessionEventDetailAllOfPublishedSelectionOptionAllOf = {
  publishedSelectionSlideContent: PublishedSelectionSlideContent
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSelectionOption =
  | (PublishedSelectionOption & LeadSessionEventDetailAllOfPublishedSelectionOptionAllOf)
  | null

export interface PublishedFormInputOption {
  createdAt: string
  formInputOptionId: Cuid
  id: Cuid
  label: string
  publishedFormInputId: Cuid
  updatedAt: string
  value: string
}

export type LeadSessionEventDetailAllOfFormAnswersItemAllOf = {
  publishedFormInput: PublishedFormInput
  publishedFormInputOptions: PublishedFormInputOption[]
}

export type LeadSessionEventDetailAllOfFormAnswersItem = FormAnswer & LeadSessionEventDetailAllOfFormAnswersItemAllOf

/**
 * @nullable
 */
export type PublishedFormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

/**
 * @nullable
 */
export type PublishedFormInputHubspotContactProperty = HubspotCreateContactProperty | null

export interface PublishedFormInput {
  createdAt: string
  formInputId: Cuid
  /** @nullable */
  hubspotContactProperty: PublishedFormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  publishedFormSlideContentId: Cuid
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PublishedFormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

export interface FormAnswer {
  createdAt: string
  id: Cuid
  leadSessionEventId: Cuid
  publishedFormInputId: Cuid
  /** @nullable */
  text: string | null
  updatedAt: string
}

export type SelectionSlideDirection = (typeof SelectionSlideDirection)[keyof typeof SelectionSlideDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideDirection = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
} as const

export interface PublishedSelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  publishedSlideId: Cuid
  text: string
  updatedAt: string
}

/**
 * @nullable
 */
export type PublishedSelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedSelectionOptionNextPublishedSlideId = Cuid | null

export interface PublishedSelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedSelectionOptionNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedSelectionOptionNextUrl
  publishedSelectionSlideContentId: Cuid
  selectionOptionId: Cuid
  updatedAt: string
  value: string
}

/**
 * @nullable
 */
export type PublishedFormSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedFormSlideContentNextPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type PublishedFormSlideContentFormThumbnailId = Cuid | null

export interface PublishedFormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: PublishedFormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedFormSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedFormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  publishedSlideId: Cuid
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

export type UnAvailableVideo = VideoBase & UnAvailableVideoAllOf

export type Video = AvailableVideo | UnAvailableVideo

/**
 * @nullable
 */
export type UnAvailableVideoAllOfDuration =
  | (typeof UnAvailableVideoAllOfDuration)[keyof typeof UnAvailableVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnAvailableVideoAllOfDuration = {} as const

/**
 * Exclude from T those types that are assignable to U
 */
export type ExcludeVideoStatusAVAILABLE = (typeof ExcludeVideoStatusAVAILABLE)[keyof typeof ExcludeVideoStatusAVAILABLE]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExcludeVideoStatusAVAILABLE = {
  ERROR: 'ERROR',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
} as const

export type UnAvailableVideoAllOf = {
  /** @nullable */
  duration: UnAvailableVideoAllOfDuration
  status: ExcludeVideoStatusAVAILABLE
}

/**
 */
export type AvailableVideoAllOfStatus = (typeof AvailableVideoAllOfStatus)[keyof typeof AvailableVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableVideoAllOfStatus = {
  AVAILABLE: 'AVAILABLE',
} as const

export type AvailableVideoAllOf = {
  duration: number
  /** */
  status: AvailableVideoAllOfStatus
}

export type VideoStatus = (typeof VideoStatus)[keyof typeof VideoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  ERROR: 'ERROR',
  AVAILABLE: 'AVAILABLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
} as const

export interface VideoBase {
  createdAt: string
  id: Cuid
  name: string
  status: VideoStatus
  tenantId: Cuid
  updatedAt: string
}

export type AvailableVideo = VideoBase & AvailableVideoAllOf

/**
 * @nullable
 */
export type PublishedVideoSlideContentVideoId = Cuid | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextPublishedSlideId = Cuid | null

export interface PublishedVideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedVideoSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedVideoSlideContentNextUrl
  publishedSlideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: PublishedVideoSlideContentVideoId
}

export type SlideType = (typeof SlideType)[keyof typeof SlideType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideType = {
  VIDEO: 'VIDEO',
  SELECTION: 'SELECTION',
  FORM: 'FORM',
} as const

export interface PublishedSlide {
  createdAt: string
  id: Cuid
  name: string
  position: number
  publishedScenarioId: Cuid
  slideId: Cuid
  type: SlideType
  updatedAt: string
}

/**
 * @nullable
 */
export type LeadSessionEventPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type LeadSessionEventPublishedSelectionOptionId = Cuid | null

export type LeadSessionEventType = (typeof LeadSessionEventType)[keyof typeof LeadSessionEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeadSessionEventType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
  SELECTION_ANSWER: 'SELECTION_ANSWER',
  FORM_ANSWER: 'FORM_ANSWER',
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface LeadSessionEvent {
  createdAt: string
  id: Cuid
  leadSessionId: Cuid
  /** @nullable */
  publishedSelectionOptionId: LeadSessionEventPublishedSelectionOptionId
  /** @nullable */
  publishedSlideId: LeadSessionEventPublishedSlideId
  /** @nullable */
  text: string | null
  type: LeadSessionEventType
  updatedAt: string
  /** @nullable */
  viewingTime: number | null
}

export type ListLeadSessionsOutputItemAllOf = {
  lead: ListLeadSessionsOutputItemAllOfLead
}

export type ListLeadSessionsOutputItem = LeadSession & ListLeadSessionsOutputItemAllOf

export type ListLeadSessionsOutput = ListLeadSessionsOutputItem[]

export interface LeadAttribute {
  createdAt: string
  id: Cuid
  leadId: Cuid
  priority: number
  publishedScenarioId: Cuid
  updatedAt: string
  value: string
}

export type ListLeadSessionsOutputItemAllOfLeadAllOf = {
  leadAttributes: LeadAttribute[]
  leadProfiles: LeadProfile[]
}

export interface Lead {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type ListLeadSessionsOutputItemAllOfLead = Lead & ListLeadSessionsOutputItemAllOfLeadAllOf

export interface LeadSession {
  createdAt: string
  id: Cuid
  isRewatch: boolean
  leadId: Cuid
  publishedScenarioId: Cuid
  updatedAt: string
}

/**
 * @nullable
 */
export type CreateLeadProfileInputNotificationEmail = Email | null

/**
 * @nullable
 */
export type CreateLeadProfileInputEmail = Email | null

export interface CreateLeadProfileInput {
  /** @nullable */
  email?: CreateLeadProfileInputEmail
  /** @nullable */
  name?: string | null
  /** @nullable */
  notificationEmail?: CreateLeadProfileInputNotificationEmail
}

/**
 * @nullable
 */
export type LeadProfileNotificationEmail = Email | null

/**
 * @nullable
 */
export type LeadProfileEmail = Email | null

/**
 * @nullable
 */
export type LeadProfileBatchOperationId = Cuid | null

export interface LeadProfile {
  /** @nullable */
  batchOperationId: LeadProfileBatchOperationId
  createdAt: string
  /** @nullable */
  email: LeadProfileEmail
  id: Cuid
  /** @nullable */
  name: string | null
  /** @nullable */
  notificationEmail: LeadProfileNotificationEmail
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlFormThumbnailAllOf = {
  url: Url
}

export type WithUrlFormThumbnail = FormThumbnail & WithUrlFormThumbnailAllOf

export interface CreateFormThumbnailInput {
  formSlideContentId: string
}

export type WithUploadUrlFormThumbnailAllOf = {
  uploadUrl: Url
}

export interface FormThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlFormThumbnail = FormThumbnail & WithUploadUrlFormThumbnailAllOf

export type UpdateFormSlideContentInput =
  PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailId

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdNextUrl =
  Url | null

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdNextSlideId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdFormThumbnailId =
  Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailId {
  buttonText?: string
  description?: string
  descriptionUnderThumbnail?: string
  design?: FormDesign
  /** @nullable */
  formThumbnailId?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdFormThumbnailId
  /** @nullable */
  nextSlideId?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdNextSlideId
  /** @nullable */
  nextUrl?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailIdNextUrl
  notificationEmail?: Email
  notifyWithFormAnswers?: boolean
  privacyPolicyCheckboxText?: string
  privacyPolicyText?: string
  privacyPolicyUrl?: Url
  thanksMailBcc?: string
  thanksMailBodyConclusion?: string
  thanksMailBodyIntroduction?: string
  thanksMailDisplayName?: string
  thanksMailEnabled?: boolean
  thanksMailSubject?: string
}

/**
 * @nullable
 */
export type FormSlideContentNextSlideId = Cuid | null

/**
 * @nullable
 */
export type FormSlideContentFormThumbnailId = Cuid | null

export type Url = string

/**
 * @nullable
 */
export type FormSlideContentNextUrl = Url | null

export type Email = string

export type FormDesign = (typeof FormDesign)[keyof typeof FormDesign]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormDesign = {
  DEFAULT: 'DEFAULT',
  WITH_THUMBNAIL: 'WITH_THUMBNAIL',
} as const

export interface FormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: FormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextSlideId: FormSlideContentNextSlideId
  /** @nullable */
  nextUrl: FormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  slideId: Cuid
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

/**
 * @nullable
 */
export type PartialOmitCreateFormInputInputFormSlideContentIdSalesforceLeadProperty =
  SalesforceCreateLeadProperty | null

/**
 * Make all properties in T optional
 */
export interface PartialOmitCreateFormInputInputFormSlideContentId {
  /** @nullable */
  hubspotContactProperty?: PartialOmitCreateFormInputInputFormSlideContentIdHubspotContactProperty
  name?: string
  placeholder?: string
  required?: boolean
  /** @nullable */
  salesforceLeadProperty?: PartialOmitCreateFormInputInputFormSlideContentIdSalesforceLeadProperty
  type?: FormInputType
}

export type UpdateFormInputInput = PartialOmitCreateFormInputInputFormSlideContentId

/**
 * @nullable
 */
export type PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdSalesforceLeadProperty =
  SalesforceCreateLeadProperty | null

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId {
  formSlideContentId: Cuid
  /** @nullable */
  hubspotContactProperty: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdHubspotContactProperty
  name: string
  placeholder: string
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdSalesforceLeadProperty
  type: FormInputType
}

export type CreateFormInputInput =
  PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId

export type HubspotCreateContactProperty =
  (typeof HubspotCreateContactProperty)[keyof typeof HubspotCreateContactProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HubspotCreateContactProperty = {
  company: 'company',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  numemployees: 'numemployees',
  phone: 'phone',
  state: 'state',
  city: 'city',
  jobtitle: 'jobtitle',
} as const

/**
 * @nullable
 */
export type PartialOmitCreateFormInputInputFormSlideContentIdHubspotContactProperty =
  HubspotCreateContactProperty | null

/**
 * @nullable
 */
export type PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdHubspotContactProperty =
  HubspotCreateContactProperty | null

/**
 * @nullable
 */
export type FormInputHubspotContactProperty = HubspotCreateContactProperty | null

export interface FormInput {
  createdAt: string
  formSlideContentId: Cuid
  /** @nullable */
  hubspotContactProperty: FormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  required: boolean
  /** @nullable */
  salesforceLeadProperty: FormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

export type SalesforceCreateLeadProperty =
  (typeof SalesforceCreateLeadProperty)[keyof typeof SalesforceCreateLeadProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesforceCreateLeadProperty = {
  Company: 'Company',
  Email: 'Email',
  FirstName: 'FirstName',
  LastName: 'LastName',
  NumberOfEmployees: 'NumberOfEmployees',
  Phone: 'Phone',
  State: 'State',
  City: 'City',
  Title: 'Title',
} as const

/**
 * @nullable
 */
export type FormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

export type FormInputType = (typeof FormInputType)[keyof typeof FormInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormInputType = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
} as const

/**
 * Make all properties in T optional
 */
export interface PartialPickFormInputOptionValueOrLabel {
  label?: string
  value?: string
}

export type UpdateFormInputOptionInput = PartialPickFormInputOptionValueOrLabel

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickFormInputOptionFormInputIdOrValueOrLabel {
  formInputId: Cuid
  label: string
  value: string
}

export type CreateFormInputOptionInput = PickFormInputOptionFormInputIdOrValueOrLabel

export interface FormInputOption {
  createdAt: string
  formInputId: Cuid
  id: Cuid
  label: string
  updatedAt: string
  value: string
}

export interface CreateEmbedScriptInput {
  floatConfig?: CreateEmbedScriptInputFloatConfig
}

/**
 * @nullable
 */
export type CreateEmbedScriptInputFloatConfigScenarioMobileThumbnailId = Cuid | null

export type MobileDisplayType = (typeof MobileDisplayType)[keyof typeof MobileDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  SIDE_LEFT: 'SIDE_LEFT',
  SIDE_RIGHT: 'SIDE_RIGHT',
  CUSTOM_TOP_LEFT: 'CUSTOM_TOP_LEFT',
  CUSTOM_TOP_RIGHT: 'CUSTOM_TOP_RIGHT',
  CUSTOM_SIDE_LEFT: 'CUSTOM_SIDE_LEFT',
  CUSTOM_SIDE_RIGHT: 'CUSTOM_SIDE_RIGHT',
  CUSTOM_BOTTOM_LEFT: 'CUSTOM_BOTTOM_LEFT',
  CUSTOM_BOTTOM_RIGHT: 'CUSTOM_BOTTOM_RIGHT',
  NONE: 'NONE',
} as const

export type DesktopDisplayHorizontalOffsetType =
  (typeof DesktopDisplayHorizontalOffsetType)[keyof typeof DesktopDisplayHorizontalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayHorizontalOffsetType = {
  FROM_LEFT: 'FROM_LEFT',
  FROM_RIGHT: 'FROM_RIGHT',
} as const

export type DesktopDisplayVerticalOffsetType =
  (typeof DesktopDisplayVerticalOffsetType)[keyof typeof DesktopDisplayVerticalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayVerticalOffsetType = {
  FROM_TOP: 'FROM_TOP',
  FROM_BOTTOM: 'FROM_BOTTOM',
} as const

export type DesktopDisplayType = (typeof DesktopDisplayType)[keyof typeof DesktopDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  CUSTOM_OFFSET: 'CUSTOM_OFFSET',
} as const

export type BatchOperationStatus = (typeof BatchOperationStatus)[keyof typeof BatchOperationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchOperationStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
} as const

export type Cuid = string

/**
 * @nullable
 */
export type VideoSlideContentVideoId = Cuid | null

export interface VideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextSlideId: VideoSlideContentNextSlideId
  /** @nullable */
  nextUrl: VideoSlideContentNextUrl
  slideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: VideoSlideContentVideoId
}

export type CreateEmbedScriptInputFloatConfig = {
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  mobileDisplayType: MobileDisplayType
  scenarioId: Cuid
  /** @nullable */
  scenarioMobileThumbnailId?: CreateEmbedScriptInputFloatConfigScenarioMobileThumbnailId
}

export interface EmbedScript {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export interface BatchOperation {
  createdAt: string
  id: Cuid
  status: BatchOperationStatus
  tenantId: Cuid
  updatedAt: string
}

export const getBatchOperation = (batchOperationId: string) => {
  return getBatchOperationMutator<BatchOperation>({ url: `/batch-operations/${batchOperationId}`, method: 'GET' })
}

export const createEmbedScript = (createEmbedScriptInput: CreateEmbedScriptInput) => {
  return createEmbedScriptMutator<EmbedScript>({
    url: `/embed-scripts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createEmbedScriptInput,
  })
}

/**
 * 視聴履歴をcsvとしてエクスポートする。

ヘッダーは
- <視聴日時>, <...[フォーム回答結果]>, <...[ユーザーアクション]>
となる

フォーム回答結果は、(publishedFormInputIdではなく)formInputId が同じものは1つの列に表示され、
ヘッダーには該当の formInput のうち最後に公開された時の入力項目名が表示される

ユーザーアクションは、
- 動画視聴のとき -> 【動画視聴】スライド名
- フォームスライド視聴のとき -> 【フォーム回答】スライド名
- 設問回答のとき -> 【設問回答】（回答時点での）設問名 / (回答時点での) 選択肢名
を表示する
 */
export const exportLeadSessions = (params: ExportLeadSessionsParams) => {
  return exportLeadSessionsMutator<string>({ url: `/export/lead-sessions`, method: 'GET', params })
}

/**
 * 設問回答割合のCSVエクスポート
 */
export const exportSelectionAnswersSummary = (params: ExportSelectionAnswersSummaryParams) => {
  return exportSelectionAnswersSummaryMutator<string>({
    url: `/export/selection-answers-summary`,
    method: 'GET',
    params,
  })
}

export const listFormInputOptions = (params: ListFormInputOptionsParams) => {
  return listFormInputOptionsMutator<FormInputOption[]>({ url: `/form-input-options`, method: 'GET', params })
}

export const createFormInputOption = (
  pickFormInputOptionFormInputIdOrValueOrLabel: PickFormInputOptionFormInputIdOrValueOrLabel,
) => {
  return createFormInputOptionMutator<FormInputOption>({
    url: `/form-input-options`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickFormInputOptionFormInputIdOrValueOrLabel,
  })
}

export const getFormInputOption = (formInputOptionId: Cuid) => {
  return getFormInputOptionMutator<FormInputOption>({ url: `/form-input-options/${formInputOptionId}`, method: 'GET' })
}

export const updateFormInputOption = (
  formInputOptionId: Cuid,
  partialPickFormInputOptionValueOrLabel: PartialPickFormInputOptionValueOrLabel,
) => {
  return updateFormInputOptionMutator<FormInputOption>({
    url: `/form-input-options/${formInputOptionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickFormInputOptionValueOrLabel,
  })
}

export const deleteFormInputOption = (formInputOptionId: Cuid) => {
  return deleteFormInputOptionMutator<void>({ url: `/form-input-options/${formInputOptionId}`, method: 'DELETE' })
}

export const listFormInputs = (params: ListFormInputsParams) => {
  return listFormInputsMutator<FormInput[]>({ url: `/form-inputs`, method: 'GET', params })
}

export const createFormInput = (
  pickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId,
) => {
  return createFormInputMutator<FormInput>({
    url: `/form-inputs`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId,
  })
}

export const getFormInput = (formInputId: Cuid) => {
  return getFormInputMutator<FormInput>({ url: `/form-inputs/${formInputId}`, method: 'GET' })
}

export const updateFormInput = (
  formInputId: Cuid,
  partialOmitCreateFormInputInputFormSlideContentId: PartialOmitCreateFormInputInputFormSlideContentId,
) => {
  return updateFormInputMutator<FormInput>({
    url: `/form-inputs/${formInputId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialOmitCreateFormInputInputFormSlideContentId,
  })
}

export const deleteFormInput = (formInputId: Cuid) => {
  return deleteFormInputMutator<void>({ url: `/form-inputs/${formInputId}`, method: 'DELETE' })
}

export const getFormSlideContentBySlideId = (params: GetFormSlideContentBySlideIdParams) => {
  return getFormSlideContentBySlideIdMutator<FormSlideContent>({
    url: `/form-slide-contents/bySlideId`,
    method: 'GET',
    params,
  })
}

export const updateFormSlideContent = (
  formSlideContentId: Cuid,
  partialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailId: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailId,
) => {
  return updateFormSlideContentMutator<FormSlideContent>({
    url: `/form-slide-contents/${formSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrNextSlideIdOrFormThumbnailId,
  })
}

export const createFormThumbnail = (createFormThumbnailInput: CreateFormThumbnailInput) => {
  return createFormThumbnailMutator<WithUploadUrlFormThumbnail>({
    url: `/form-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFormThumbnailInput,
  })
}

export const getFormThumbnail = (formThumbnailId: Cuid) => {
  return getFormThumbnailMutator<WithUrlFormThumbnail>({ url: `/form-thumbnails/${formThumbnailId}`, method: 'GET' })
}

export const listLeadProfiles = (params?: ListLeadProfilesParams) => {
  return listLeadProfilesMutator<LeadProfile[]>({ url: `/lead-profiles`, method: 'GET', params })
}

export const createLeadProfile = (createLeadProfileInput: CreateLeadProfileInput) => {
  return createLeadProfileMutator<LeadProfile>({
    url: `/lead-profiles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadProfileInput,
  })
}

export const batchCreateLeadProfiles = (createLeadProfileInput: CreateLeadProfileInput[]) => {
  return batchCreateLeadProfilesMutator<BatchOperation>({
    url: `/lead-profiles/_batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadProfileInput,
  })
}

export const listLeadSessions = (params: ListLeadSessionsParams) => {
  return listLeadSessionsMutator<ListLeadSessionsOutput>({ url: `/lead-sessions`, method: 'GET', params })
}

export const getLeadSessionDetail = (leadSessionId: Cuid) => {
  return getLeadSessionDetailMutator<LeadSessionDetail>({
    url: `/lead-sessions/${leadSessionId}/detail`,
    method: 'GET',
  })
}

export const deleteLeadSession = (leadSessionId: Cuid) => {
  return deleteLeadSessionMutator<void>({ url: `/lead-sessions/${leadSessionId}`, method: 'DELETE' })
}

export const listPublishedVideoSlideContents = (params?: ListPublishedVideoSlideContentsParams) => {
  return listPublishedVideoSlideContentsMutator<PublishedVideoSlideContent[]>({
    url: `/published-video-slide-contents`,
    method: 'GET',
    params,
  })
}

export const createScenarioLogo = (createScenarioLogoInput: CreateScenarioLogoInput) => {
  return createScenarioLogoMutator<WithUploadUrlScenarioLogo>({
    url: `/scenario-logos`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioLogoInput,
  })
}

export const getScenarioLogo = (scenarioLogoId: Cuid) => {
  return getScenarioLogoMutator<WithUrlScenarioLogo>({ url: `/scenario-logos/${scenarioLogoId}`, method: 'GET' })
}

export const createScenarioMobileThumbnail = (
  createScenarioMobileThumbnailInput: CreateScenarioMobileThumbnailInput,
) => {
  return createScenarioMobileThumbnailMutator<WithUploadUrlScenarioMobileThumbnail>({
    url: `/scenario-mobile-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioMobileThumbnailInput,
  })
}

export const getScenarioMobileThumbnail = (scenarioMobileThumbnailId: Cuid) => {
  return getScenarioMobileThumbnailMutator<WithUrlScenarioMobileThumbnail>({
    url: `/scenario-mobile-thumbnails/${scenarioMobileThumbnailId}`,
    method: 'GET',
  })
}

export const listScenarios = () => {
  return listScenariosMutator<Scenario[]>({ url: `/scenarios`, method: 'GET' })
}

export const createScenario = (createScenarioInput: CreateScenarioInput) => {
  return createScenarioMutator<Scenario>({
    url: `/scenarios`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioInput,
  })
}

export const getScenario = (scenarioId: Cuid) => {
  return getScenarioMutator<Scenario>({ url: `/scenarios/${scenarioId}`, method: 'GET' })
}

export const updateScenario = (
  scenarioId: Cuid,
  partialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublic: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublic,
) => {
  return updateScenarioMutator<Scenario>({
    url: `/scenarios/${scenarioId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrIsPublic,
  })
}

export const deleteScenario = (scenarioId: Cuid) => {
  return deleteScenarioMutator<void>({ url: `/scenarios/${scenarioId}`, method: 'DELETE' })
}

export const getScenarioDetail = (scenarioId: Cuid) => {
  return getScenarioDetailMutator<ScenarioDetail>({ url: `/scenarios/${scenarioId}/detail`, method: 'GET' })
}

export const getScenarioStatsSummary = (scenarioId: Cuid, params: GetScenarioStatsSummaryParams) => {
  return getScenarioStatsSummaryMutator<ScenarioStatsSummary>({
    url: `/scenarios/${scenarioId}/stats/summary`,
    method: 'GET',
    params,
  })
}

export const getDailyScenarioStats = (scenarioId: Cuid, params: GetDailyScenarioStatsParams) => {
  return getDailyScenarioStatsMutator<DailyScenarioStats[]>({
    url: `/scenarios/${scenarioId}/stats/daily`,
    method: 'GET',
    params,
  })
}

export const publishScenario = (scenarioId: Cuid) => {
  return publishScenarioMutator<void>({ url: `/scenarios/${scenarioId}/publish`, method: 'POST' })
}

export const createScenarioThumbnail = (createScenarioThumbnailInput: CreateScenarioThumbnailInput) => {
  return createScenarioThumbnailMutator<WithUploadUrlScenarioThumbnail>({
    url: `/scenario-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioThumbnailInput,
  })
}

export const getScenarioThumbnail = (scenarioThumbnailId: Cuid) => {
  return getScenarioThumbnailMutator<WithUrlScenarioThumbnail>({
    url: `/scenario-thumbnails/${scenarioThumbnailId}`,
    method: 'GET',
  })
}

export const listSelectionOptions = (params: ListSelectionOptionsParams) => {
  return listSelectionOptionsMutator<SelectionOption[]>({ url: `/selection-options`, method: 'GET', params })
}

export const createSelectionOption = (
  pickSelectionOptionSelectionSlideContentId: PickSelectionOptionSelectionSlideContentId,
) => {
  return createSelectionOptionMutator<SelectionOption>({
    url: `/selection-options`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickSelectionOptionSelectionSlideContentId,
  })
}

export const getSelectionOption = (selectionOptionId: Cuid) => {
  return getSelectionOptionMutator<SelectionOption>({ url: `/selection-options/${selectionOptionId}`, method: 'GET' })
}

export const updateSelectionOption = (
  selectionOptionId: Cuid,
  partialPickSelectionOptionValueOrNextSlideIdOrNextUrl: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrl,
) => {
  return updateSelectionOptionMutator<SelectionOption>({
    url: `/selection-options/${selectionOptionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickSelectionOptionValueOrNextSlideIdOrNextUrl,
  })
}

export const deleteSelectionOption = (selectionOptionId: Cuid) => {
  return deleteSelectionOptionMutator<void>({ url: `/selection-options/${selectionOptionId}`, method: 'DELETE' })
}

export const getSelectionSlideContentBySlideId = (params: GetSelectionSlideContentBySlideIdParams) => {
  return getSelectionSlideContentBySlideIdMutator<SelectionSlideContent>({
    url: `/selection-slide-contents/bySlideId`,
    method: 'GET',
    params,
  })
}

export const updateSelectionSlideContent = (
  selectionSlideContentId: Cuid,
  partialPickSelectionSlideContentTextOrDirection: PartialPickSelectionSlideContentTextOrDirection,
) => {
  return updateSelectionSlideContentMutator<SelectionSlideContent>({
    url: `/selection-slide-contents/${selectionSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickSelectionSlideContentTextOrDirection,
  })
}

/**
 * @summary スライド一覧を取得
 */
export const listSlides = (params: ListSlidesParams) => {
  return listSlidesMutator<Slide[]>({ url: `/slides`, method: 'GET', params })
}

/**
 * @summary スライドの作成
 */
export const createSlide = (pickSlideScenarioIdOrNameOrTypeOrPosition: PickSlideScenarioIdOrNameOrTypeOrPosition) => {
  return createSlideMutator<Slide>({
    url: `/slides`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickSlideScenarioIdOrNameOrTypeOrPosition,
  })
}

export const getSlide = (slideId: Cuid) => {
  return getSlideMutator<Slide>({ url: `/slides/${slideId}`, method: 'GET' })
}

export const updateSlide = (slideId: Cuid, updateSlideInput: UpdateSlideInput) => {
  return updateSlideMutator<Slide>({
    url: `/slides/${slideId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateSlideInput,
  })
}

export const deleteSlide = (slideId: Cuid) => {
  return deleteSlideMutator<void>({ url: `/slides/${slideId}`, method: 'DELETE' })
}

export const listTenants = () => {
  return listTenantsMutator<Tenant[]>({ url: `/tenants`, method: 'GET' })
}

export const getTenant = (tenantId: string) => {
  return getTenantMutator<Tenant>({ url: `/tenants/${tenantId}`, method: 'GET' })
}

export const updateTenant = (
  tenantId: string,
  partialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmail: PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmail,
) => {
  return updateTenantMutator<Tenant>({
    url: `/tenants/${tenantId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmail,
  })
}

export const synthesizeSpeech = (synthesizeSpeechInput: SynthesizeSpeechInput) => {
  return synthesizeSpeechMutator<string>({
    url: `/text-to-speech/synthesizeSpeech`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: synthesizeSpeechInput,
  })
}

export const getMe = () => {
  return getMeMutator<Me>({ url: `/user`, method: 'GET' })
}

export const updateMe = (
  partialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorId: PartialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorId,
) => {
  return updateMeMutator<Me>({
    url: `/user`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickMeFirstNameOrLastNameOrEmailOrVideoAvatorId,
  })
}

export const listStarredScenarios = () => {
  return listStarredScenariosMutator<Scenario[]>({ url: `/user/marking-scenarios`, method: 'GET' })
}

export const starScenario = (scenarioId: Cuid) => {
  return starScenarioMutator<void>({ url: `/user/marking-scenarios/${scenarioId}`, method: 'PUT' })
}

export const unstarScenario = (scenarioId: Cuid) => {
  return unstarScenarioMutator<void>({ url: `/user/marking-scenarios/${scenarioId}`, method: 'DELETE' })
}

export const createUser = (createUserInput: CreateUserInput) => {
  return createUserMutator<SimpleUser>({
    url: `/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserInput,
  })
}

export const listUsers = () => {
  return listUsersMutator<User[]>({ url: `/users`, method: 'GET' })
}

export const updateUser = (userId: string, updateUserInput: UpdateUserInput) => {
  return updateUserMutator<SimpleUser>({
    url: `/users/${userId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserInput,
  })
}

export const deleteUser = (userId: string) => {
  return deleteUserMutator<void>({ url: `/users/${userId}`, method: 'DELETE' })
}

export const createVideoAvator = () => {
  return createVideoAvatorMutator<WithUploadUrlVideoAvator>({ url: `/video-avators`, method: 'POST' })
}

export const getVideoAvator = (videoAvatorId: Cuid) => {
  return getVideoAvatorMutator<WithUrlVideoAvator>({ url: `/video-avators/${videoAvatorId}`, method: 'GET' })
}

export const listVideos = (params?: ListVideosParams) => {
  return listVideosMutator<Video[]>({ url: `/videos`, method: 'GET', params })
}

export const createVideo = (createVideoInput: CreateVideoInput) => {
  return createVideoMutator<WithUploadUrlVideoAndStatusUPLOADING>({
    url: `/videos`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createVideoInput,
  })
}

export const getVideo = (videoId: Cuid, params?: GetVideoParams) => {
  return getVideoMutator<VideoWithUrl>({ url: `/videos/${videoId}`, method: 'GET', params })
}

export const updateVideo = (videoId: Cuid, updateVideoInput: UpdateVideoInput) => {
  return updateVideoMutator<Video>({
    url: `/videos/${videoId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateVideoInput,
  })
}

export const deleteVideo = (videoId: Cuid) => {
  return deleteVideoMutator<void>({ url: `/videos/${videoId}`, method: 'DELETE' })
}

export const getVideoCaption = (videoId: Cuid) => {
  return getVideoCaptionMutator<VideoCaptionSegment[]>({ url: `/videos/${videoId}/caption`, method: 'GET' })
}

export const setVideoCaption = (videoId: Cuid, setVideoCaptionInput: SetVideoCaptionInput) => {
  return setVideoCaptionMutator<void>({
    url: `/videos/${videoId}/caption`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: setVideoCaptionInput,
  })
}

export const listVideoSlideContent = (params?: ListVideoSlideContentParams) => {
  return listVideoSlideContentMutator<VideoSlideContent[]>({ url: `/video-slide-contents`, method: 'GET', params })
}

export const getVideoSlideContentBySlideId = (params: GetVideoSlideContentBySlideIdParams) => {
  return getVideoSlideContentBySlideIdMutator<VideoSlideContent>({
    url: `/video-slide-contents/bySlideId`,
    method: 'GET',
    params,
  })
}

export const updateVideoSlideContent = (
  videoSlideContentId: Cuid,
  partialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl,
) => {
  return updateVideoSlideContentMutator<VideoSlideContent>({
    url: `/video-slide-contents/${videoSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl,
  })
}

export type GetBatchOperationResult = NonNullable<Awaited<ReturnType<typeof getBatchOperation>>>
export type CreateEmbedScriptResult = NonNullable<Awaited<ReturnType<typeof createEmbedScript>>>
export type ExportLeadSessionsResult = NonNullable<Awaited<ReturnType<typeof exportLeadSessions>>>
export type ExportSelectionAnswersSummaryResult = NonNullable<Awaited<ReturnType<typeof exportSelectionAnswersSummary>>>
export type ListFormInputOptionsResult = NonNullable<Awaited<ReturnType<typeof listFormInputOptions>>>
export type CreateFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof createFormInputOption>>>
export type GetFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof getFormInputOption>>>
export type UpdateFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof updateFormInputOption>>>
export type DeleteFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof deleteFormInputOption>>>
export type ListFormInputsResult = NonNullable<Awaited<ReturnType<typeof listFormInputs>>>
export type CreateFormInputResult = NonNullable<Awaited<ReturnType<typeof createFormInput>>>
export type GetFormInputResult = NonNullable<Awaited<ReturnType<typeof getFormInput>>>
export type UpdateFormInputResult = NonNullable<Awaited<ReturnType<typeof updateFormInput>>>
export type DeleteFormInputResult = NonNullable<Awaited<ReturnType<typeof deleteFormInput>>>
export type GetFormSlideContentBySlideIdResult = NonNullable<Awaited<ReturnType<typeof getFormSlideContentBySlideId>>>
export type UpdateFormSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateFormSlideContent>>>
export type CreateFormThumbnailResult = NonNullable<Awaited<ReturnType<typeof createFormThumbnail>>>
export type GetFormThumbnailResult = NonNullable<Awaited<ReturnType<typeof getFormThumbnail>>>
export type ListLeadProfilesResult = NonNullable<Awaited<ReturnType<typeof listLeadProfiles>>>
export type CreateLeadProfileResult = NonNullable<Awaited<ReturnType<typeof createLeadProfile>>>
export type BatchCreateLeadProfilesResult = NonNullable<Awaited<ReturnType<typeof batchCreateLeadProfiles>>>
export type ListLeadSessionsResult = NonNullable<Awaited<ReturnType<typeof listLeadSessions>>>
export type GetLeadSessionDetailResult = NonNullable<Awaited<ReturnType<typeof getLeadSessionDetail>>>
export type DeleteLeadSessionResult = NonNullable<Awaited<ReturnType<typeof deleteLeadSession>>>
export type ListPublishedVideoSlideContentsResult = NonNullable<
  Awaited<ReturnType<typeof listPublishedVideoSlideContents>>
>
export type CreateScenarioLogoResult = NonNullable<Awaited<ReturnType<typeof createScenarioLogo>>>
export type GetScenarioLogoResult = NonNullable<Awaited<ReturnType<typeof getScenarioLogo>>>
export type CreateScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof createScenarioMobileThumbnail>>>
export type GetScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioMobileThumbnail>>>
export type ListScenariosResult = NonNullable<Awaited<ReturnType<typeof listScenarios>>>
export type CreateScenarioResult = NonNullable<Awaited<ReturnType<typeof createScenario>>>
export type GetScenarioResult = NonNullable<Awaited<ReturnType<typeof getScenario>>>
export type UpdateScenarioResult = NonNullable<Awaited<ReturnType<typeof updateScenario>>>
export type DeleteScenarioResult = NonNullable<Awaited<ReturnType<typeof deleteScenario>>>
export type GetScenarioDetailResult = NonNullable<Awaited<ReturnType<typeof getScenarioDetail>>>
export type GetScenarioStatsSummaryResult = NonNullable<Awaited<ReturnType<typeof getScenarioStatsSummary>>>
export type GetDailyScenarioStatsResult = NonNullable<Awaited<ReturnType<typeof getDailyScenarioStats>>>
export type PublishScenarioResult = NonNullable<Awaited<ReturnType<typeof publishScenario>>>
export type CreateScenarioThumbnailResult = NonNullable<Awaited<ReturnType<typeof createScenarioThumbnail>>>
export type GetScenarioThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioThumbnail>>>
export type ListSelectionOptionsResult = NonNullable<Awaited<ReturnType<typeof listSelectionOptions>>>
export type CreateSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof createSelectionOption>>>
export type GetSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof getSelectionOption>>>
export type UpdateSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof updateSelectionOption>>>
export type DeleteSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof deleteSelectionOption>>>
export type GetSelectionSlideContentBySlideIdResult = NonNullable<
  Awaited<ReturnType<typeof getSelectionSlideContentBySlideId>>
>
export type UpdateSelectionSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateSelectionSlideContent>>>
export type ListSlidesResult = NonNullable<Awaited<ReturnType<typeof listSlides>>>
export type CreateSlideResult = NonNullable<Awaited<ReturnType<typeof createSlide>>>
export type GetSlideResult = NonNullable<Awaited<ReturnType<typeof getSlide>>>
export type UpdateSlideResult = NonNullable<Awaited<ReturnType<typeof updateSlide>>>
export type DeleteSlideResult = NonNullable<Awaited<ReturnType<typeof deleteSlide>>>
export type ListTenantsResult = NonNullable<Awaited<ReturnType<typeof listTenants>>>
export type GetTenantResult = NonNullable<Awaited<ReturnType<typeof getTenant>>>
export type UpdateTenantResult = NonNullable<Awaited<ReturnType<typeof updateTenant>>>
export type SynthesizeSpeechResult = NonNullable<Awaited<ReturnType<typeof synthesizeSpeech>>>
export type GetMeResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type UpdateMeResult = NonNullable<Awaited<ReturnType<typeof updateMe>>>
export type ListStarredScenariosResult = NonNullable<Awaited<ReturnType<typeof listStarredScenarios>>>
export type StarScenarioResult = NonNullable<Awaited<ReturnType<typeof starScenario>>>
export type UnstarScenarioResult = NonNullable<Awaited<ReturnType<typeof unstarScenario>>>
export type CreateUserResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
export type ListUsersResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type UpdateUserResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
export type DeleteUserResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
export type CreateVideoAvatorResult = NonNullable<Awaited<ReturnType<typeof createVideoAvator>>>
export type GetVideoAvatorResult = NonNullable<Awaited<ReturnType<typeof getVideoAvator>>>
export type ListVideosResult = NonNullable<Awaited<ReturnType<typeof listVideos>>>
export type CreateVideoResult = NonNullable<Awaited<ReturnType<typeof createVideo>>>
export type GetVideoResult = NonNullable<Awaited<ReturnType<typeof getVideo>>>
export type UpdateVideoResult = NonNullable<Awaited<ReturnType<typeof updateVideo>>>
export type DeleteVideoResult = NonNullable<Awaited<ReturnType<typeof deleteVideo>>>
export type GetVideoCaptionResult = NonNullable<Awaited<ReturnType<typeof getVideoCaption>>>
export type SetVideoCaptionResult = NonNullable<Awaited<ReturnType<typeof setVideoCaption>>>
export type ListVideoSlideContentResult = NonNullable<Awaited<ReturnType<typeof listVideoSlideContent>>>
export type GetVideoSlideContentBySlideIdResult = NonNullable<Awaited<ReturnType<typeof getVideoSlideContentBySlideId>>>
export type UpdateVideoSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateVideoSlideContent>>>
