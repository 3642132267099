import useSWR, { BareFetcher, Key, SWRConfiguration } from 'swr'

/**
 * SuspenseモードのSWR
 *
 * 返り値を not defined で扱えたほうが便利なため、falsy key を許可しない
 */
export default function useSuspenseSwr<Data, Error = unknown>(
  key: Key,
  fetcher: BareFetcher<Data> | null,
  config: SWRConfiguration<Data, Error, BareFetcher<Data>> = {},
) {
  config.suspense = true

  const { data, ...rest } = useSWR(key, fetcher, config)

  if (data === undefined) {
    throw Error('The data of SWR result is undefined. The SWR key is possible falsy.')
  }

  return { data, ...rest }
}

export const immutableOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
} as const
