import { chakra, Image } from '@chakra-ui/react'
import { FC } from 'react'
import { VisualMediaProps } from './VisualMediaProps'

export type VisualMediaPresentationProps = VisualMediaProps & {
  Image: typeof Image
}

const VisualMediaPresentation: FC<VisualMediaPresentationProps> = ({ src, Image, ...mediaProps }) => {
  return (
    <Image
      src={src}
      {...mediaProps}
      fallbackStrategy="onError"
      fallback={<chakra.video src={src} muted autoPlay loop {...mediaProps} />}
    />
  )
}

export default VisualMediaPresentation
