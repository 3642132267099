import { FC } from 'react'
import { SelectionAnswerHistoryItem } from '../needsTests/ScenarioPlayer/ScenarioPlayerProvider'
import SelectionAnswerHistoryListItemPresentation from './SelectionAnswerHistoryListItemPresentation'

export type SelectionAnswerHistoryListItemProps = {
  selectionAnswerHistoryItem: SelectionAnswerHistoryItem
  goBackToSelectionSlide: (selectionAnswerHistoryIndex: number) => void
  historyIndex: number
}
const SelectionAnswerHistoryListItem: FC<SelectionAnswerHistoryListItemProps> = (props) => {
  return <SelectionAnswerHistoryListItemPresentation {...props} />
}

export default SelectionAnswerHistoryListItem
