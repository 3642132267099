import { Box, Button, HStack, Icon, Tooltip, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import Body from '../../elements/typography/Body'
import { SelectionAnswerHistoryListItemProps } from './SelectionAnswerHistoryListItem'

export type SelectionAnswerHistoryListPresentationProps = SelectionAnswerHistoryListItemProps

const TOOLTIP_THRESHOLD = 15 // 文字数がこの値を超えた場合にTooltipを表示する

const SelectionAnswerHistoryListItemPresentation: FC<SelectionAnswerHistoryListPresentationProps> = ({
  selectionAnswerHistoryItem,
  goBackToSelectionSlide,
  historyIndex,
}) => {
  const question = `Q. ${selectionAnswerHistoryItem.publishedSelectionSlideContentText}`
  const answer = `A. ${selectionAnswerHistoryItem.publishedSelectionOptionValue}`
  return (
    <VStack
      align="start"
      bg="white"
      rounded={8}
      p={3}
      spacing={4}
      width="100%"
      height="100%"
      border="1px solid"
      borderColor="gray.lighter"
    >
      <VStack spacing={2} width="100%">
        <Box width="100%" whiteSpace="nowrap">
          <Tooltip label={question} placement="top" isDisabled={question.length < TOOLTIP_THRESHOLD} fontSize={14}>
            <Body fontSize="14px" overflow="hidden" textOverflow="ellipsis">
              {question}
            </Body>
          </Tooltip>
        </Box>
        <Box rounded={8} p={2} bg="background" width="100%" whiteSpace="nowrap">
          <Tooltip label={answer} placement="top" isDisabled={answer.length < TOOLTIP_THRESHOLD} fontSize={14}>
            <Body fontSize="14px" width="100%" fontWeight="bold" textOverflow="ellipsis" overflow="hidden">
              {answer}
            </Body>
          </Tooltip>
        </Box>
      </VStack>
      <Button
        variant="link"
        borderRadius={0}
        onClick={() => goBackToSelectionSlide(historyIndex)}
        data-testid="go-back-to-selection-slide-button"
      >
        <HStack spacing={1} color="gray.basic">
          <Icon as={BsArrowCounterclockwise} boxSize="16px" />
          <Body fontSize="14">この設問に戻る</Body>
        </HStack>
      </Button>
    </VStack>
  )
}

export default SelectionAnswerHistoryListItemPresentation
